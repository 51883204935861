export default theme => ({
  calendarWrapper: {
    margin: '4px 0 10px 0'
  },
  regularFontWeight: {
    fontWeight: 300
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  marginRight: {
    marginRight: 24
  },
  marginLeft: {
    marginLeft: 24
  },
});
