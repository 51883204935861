import { useState, useRef, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Navigation, FreeMode, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { startOfWeek, sub, add, eachDayOfInterval, startOfDay } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns-tz';
import { useWeekStart } from 'hooks/useWeekStart';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';
import DaySliderItem from './DaySliderItem';

const getDayInfoByDate = (date) => {
  return {
    date: date, // date obj
    formatDay: format(date, GLOBAL_DATE_FORMAT.dayContraction), // format Sun = S
    dayNum: date.getDate(), // date num
  };
};

const getWeek = (weekDayFrom0) => {
  const today = new Date();
  const dayBeforeWeekStart = sub(startOfWeek(today, { weekStartsOn: weekDayFrom0 }), { days: 1 });
  const dayAfterWeekEnd = add(dayBeforeWeekStart, { days: 8 })
  
  return eachDayOfInterval({ start: dayBeforeWeekStart, end: dayAfterWeekEnd }).map(getDayInfoByDate);
}


const CarouselCalendar = ({ classes, onDateSelected }) => {
  const swiperRef = useRef(null);
  const { weekDayFromO } = useWeekStart();

  const openSlide = (slideIndex) => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.swiper.slideTo(slideIndex); // go to some index slide
    }
  };

  const week = useMemo(() => getWeek(weekDayFromO), [format(new Date(), 'yyyy-MM-dd')]);

  const [selectedCarouselDate, setSelectedCarouselDate] = useState(startOfDay(new Date()));

  const handleDateSelect = (date) => {
    setSelectedCarouselDate(date);
    onDateSelected(date);
  }

  useEffect(() => {
    openSlide(1);
  }, [week, swiperRef]);
  

  return (
    <>
      <Grid container style={{ padding: '0 15px' }}>
        <Typography variant="h6" color="textSecondary">
          {format(week[0].date, 'MMMM')}
        </Typography>
      </Grid>
      <Swiper
        ref={swiperRef}
        slidesPerView={7}
        className="my-schedule-slider"
        modules={[Navigation, Pagination, FreeMode, Scrollbar, A11y]}
        activeIndex={0}
      >
        {week.map((day, i) => (
          <SwiperSlide key={i} virtualIndex={i} style={{ display: 'flex', justifyContent: 'center' }}>
            <DaySliderItem
              dayItem={day}
              selectedCarouselDate={selectedCarouselDate}
              setSelectedCarouselDate={handleDateSelect}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default withStyles(styles)(CarouselCalendar);
