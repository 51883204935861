import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';

import ConfirmDialog from 'components/ConfirmDialog';

// import FiltersBar from '../../components/FiltersBar';
import FiltersBar from 'components/FiltersBar';
import ManufacturerCard from './ManufacturerCard';
import SortingPanel from './SortingPanel';
import PageLoader from 'components/PageLoader';
import { fetchManufacturers, fetchDeleteManufacture } from 'store/supplies/suppliesOperations';
import suppliesActions from 'store/supplies/suppliesActions';
import { exportData, importData } from 'helpers/downloadFile';

const searchOptions = {
  shouldSort: true,
  keys: ['publication408', 'designation', 'supplierCode', 'name'],
};

const Manufacturers = ({ onSelect, setSnackbar }) => {
  const dispatch = useDispatch();
  const [fuse, setFuse] = useState(null);
  const [drawer, setDrawer] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [sorting, setSorting] = useState({ sortBy: 'name', sortOrder: 'asc' });
  const [deletingManufacturer, setDeletingManufacturer] = useState(false);

  const manufacturersData = useSelector((state) => state.supplies.manufacturers.data);
  const successfullyManufacturerDeleted = useSelector(
    (state) => state.supplies.manufacturers.successfullyManufacturerDeleted
  );
  const snackbar = useSelector((state) => state.supplies.manufacturers.snackBarInfo);
  const loading = useSelector((state) => state.supplies.manufacturers.loading);

  useEffect(() => {
    dispatch(fetchManufacturers());
  }, []);

  useEffect(() => {
    if (snackbar) {
      setSnackbar(snackbar);
      dispatch(suppliesActions.postManufactureError(false));
    }
  }, [snackbar]);

  useEffect(() => {
    if (successfullyManufacturerDeleted) {
      dispatch(suppliesActions.isDeleteManufacturerSuccess(false));
    }
    setDeletingManufacturer(null);
  }, [successfullyManufacturerDeleted]);

  const openDrawer = useCallback(
    () => () => {
      setDrawer({ isOpen: true });
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setDrawer({ isOpen: false });
  }, []);

  const onUpdate = useCallback((values) => {
    setSorting(values);
    dispatch(fetchManufacturers(values));
    closeDrawer();
  }, []);

  const onSortingCancel = useCallback(() => {
    setSorting({
      sortBy: 'name',
      sortOrder: 'asc',
    });
    dispatch(fetchManufacturers());
    closeDrawer();
  }, []);

  const onDelete = useCallback((manufacturer) => {
    setDeletingManufacturer(manufacturer);
  }, []);

  const onDeletingConfirmed = useCallback(async () => {
    await dispatch(fetchDeleteManufacture(deletingManufacturer._id));
    setSnackbar({
      text: 'Successfully Deleted!',
      type: 'success',
    });
  });

  const onExportExcel = () => {
    exportData(
      'manufacturers/csv',
      {
        searchValue,
      },
      (type, text) => {
        setSnackbar({
          type,
          text,
        });
      }
    );
  };

  const onImportExcel = async (data) => {
    importData('manufacturers/csv', data, (type, text) => {
      setSnackbar({
        type,
        text,
      });
    }).then((e) => dispatch(fetchManufacturers()));
  };

  useMemo(() => {
    setFuse(new Fuse(manufacturersData, searchOptions));
  }, [manufacturersData]);

  const filteredManufacturers = fuse && searchValue ? fuse.search(searchValue) : manufacturersData;

  return loading ? (
    <PageLoader loading>
      <div style={{ height: 100 }} />
    </PageLoader>
  ) : (
    <>
      <FiltersBar
        openDrawer={openDrawer}
        updateSearchValue={setSearchValue}
        isDynamicSearch={true}
        hasImport={true}
        hasExport={true}
        onExportData={onExportExcel}
        onImportData={onImportExcel}
      />
      <Grid container spacing={2}>
        {filteredManufacturers.map((m) => (
          <Grid key={m._id} item xs={12} md={6} lg={3}>
            <ManufacturerCard data={m} onDelete={onDelete} onClick={() => onSelect(m)} />
          </Grid>
        ))}
      </Grid>
      {drawer && (
        <Drawer anchor="right" open={drawer.isOpen}>
          <SortingPanel
            closeDrawer={closeDrawer}
            onCancel={onSortingCancel}
            sorting={sorting}
            onUpdate={onUpdate}
          />
        </Drawer>
      )}
      {deletingManufacturer && (
        <ConfirmDialog
          isOpen={!!deletingManufacturer}
          onClose={() => setDeletingManufacturer(null)}
          onSubmit={onDeletingConfirmed}
          text="Are you sure you want to delete this manufacturer?"
          cancelBtn={'Cancel'}
          confirmBtn={'Delete'}
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};

Manufacturers.defaultProps = {
  onSelect: () => {},
};
export default Manufacturers;
