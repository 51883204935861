import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { isSameDay } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { updateSelectedDate } from 'store/schedule/schedulePageOperation';


const DaySliderItem = ({ classes, dayItem, selectedCarouselDate, setSelectedCarouselDate }) => {
  const dispatch = useDispatch();

  const onSelectDate = (date) => {
    dispatch(updateSelectedDate(date));
    if (setSelectedCarouselDate) setSelectedCarouselDate(date);
  };

  return (
    <div
      className={classnames(
        classes.dayWrapper,
        isSameDay(dayItem.date, selectedCarouselDate) && classes.selectedDay
      )}
      onClick={() => onSelectDate(dayItem.date)}
    >
      <Typography variant="h6" color="textSecondary">
        {dayItem.formatDay}
      </Typography>
      <Typography variant="h5" color="textPrimary">
        {dayItem.dayNum}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(DaySliderItem);
