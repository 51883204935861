import React, { useEffect, useState, useMemo, useRef } from 'react';
import propTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../../../../PageLoader2/PageLoader2';
import {
  useSheetFormStyles,
  useResourcesFormStyles,
  useSheetDetailsStyles,
} from '../../../../hooks';
import DatePickerInput from '../../../../../DatePickerInput';
import { getContactName, getProjectName, checkUserRole } from 'helpers/_helpers';
import AlertModal from '../../../../../AlertModal/AlertModal';
import { isIOS, Option } from '../../../../../Option';
import OverlappingModal from '../../../../../OverlapsingModal';
import { updateSheetAddResource } from 'store/schedule/schedulePageOperation';
import { useTimeStates } from 'hooks/useTimeStates';
import { parse, startOfDay, format, endOfWeek, startOfWeek, subDays, addDays } from 'date-fns';
import { patchWorklogGeneralInfo } from 'store/currentWorklog/operations';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';
import LocationCreator from './LocationCreator/LocationCreator';
import { Divider } from '@material-ui/core';
import _ from 'lodash';
import classnames from 'classnames';
import { AlternateContacts } from './components/AlternateContacts/AlternateContacts';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';
import { useWeekStart } from 'hooks/useWeekStart';

const defaultModalState = { isOpen: false, text: '' };

const GeneralInfoForm = ({ closeDrawer }) => {
  const dispatch = useDispatch();
  const deletedAllLocations = useRef(null);
  const sheetFormClasses = useSheetFormStyles();
  const resourcesFormClasses = useResourcesFormStyles();
  const sheetDetailsClasses = useSheetDetailsStyles();

  const [contractors, setContractors] = useState([]);
  const [currContractorContacts, setCurrContractorContacts] = useState([]); //Fully Contacts
  const [projects, setProjects] = useState([]);
  const [projectLocations, setProjectLocations] = useState([]); //Fully location
  const [projectRallyPoints, setProjectRallyPoints] = useState([]); //Fully rally
  const [infoModal, setInfoModal] = useState(defaultModalState);
  const [overlappingModal, setOverlappingModal] = useState(null);
  const [sheetLocations, setSheetLocations] = useState(['']); //actual location _ids in selector
  const [sheetRallyPoints, setSheetRallyPoints] = useState(['']); //actual rally _ids in selector
  const [locationEditor, setLocationEditor] = useState(false);
  const [rallyPointEditor, setRallyPointEditor] = useState(false);
  const [showAddContactBtn, setShowAddContactBtn] = useState(true);

  const { minutes } = useTimeStates({ minuteStep: 5, takeSettingsHours: true });
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
    register,
    watch,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { weekDayFromO } = useWeekStart();
  const { dateFormat } = useDateSettingsFormat();
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const allProjectsStored = useSelector((store) => store.projectTypes.projects.data);
  const allContractors = useSelector((store) => store.contractors.data);
  const contractorsLoading = useSelector((store) => store.contractors.isLoading);
  const allProjects = useMemo(
    () => allProjectsStored.filter((proj) => proj.active),
    [allProjectsStored]
  );

  useEffect(() => {
    register({ name: 'projectId' });
    register({ name: 'contractorId' });
    register({ name: 'locations' });
    register({ name: 'rallyPoints' });
  }, [register]);

  const alternateContactsWatcher = watch('alternateContacts');
  useEffect(() => {
    handleShowAddContactBtn();
  }, [alternateContactsWatcher]);

  useEffect(() => {
    if (!sheet || !Object.keys(sheet).length) return;
    const contractorId =
      (sheet.project && sheet.project.contractor && sheet.project.contractor.oldContractorId) ||
      sheet.contractorId;

    const projects = allProjects.filter((project) => project.contractorId === contractorId);

    const nextLocations =
      sheet.project?.locations && Array.isArray(sheet.project.locations)
        ? sheet.project.locations
        : [];
    const nextRallyPoints =
      sheet.project?.rallyPoints && Array.isArray(sheet.project.rallyPoints)
        ? sheet.project.rallyPoints
        : [];

    setContractors(allContractors);
    setProjects(projects);

    setProjectLocations(_.uniqBy([...nextLocations, ...(sheet?.sheetLocations || [])], '_id'));

    setProjectRallyPoints(
      _.uniqBy([...nextRallyPoints, ...(sheet?.sheetRallyPoints || [])], '_id')
    );

    setValue('contractorId', contractorId);
    setValue('forceAccount', sheet.forceAccount);
    setValue('createdAt', new Date(sheet.createdAt));
    // setValue('gridDate', sheet.grid.date);
    setValue(
      'gridDate',
      format(parse(sheet.grid.date, globalBEDateFormat, new Date()), dateFormat)
    );
    setValue('projectId', (sheet.project && sheet.project.oldProjectId) || sheet.projectId);

    const contractor = allContractors.find((contractor) => contractor._id === contractorId);

    const sheetAlternateContacts = [
      sheet?.primaryContact?._id || '',
      ...(sheet?.alternateContacts?.map((el) => el._id) || []),
    ];
    const sheetAllFullContacts = sheet?.primaryContact
      ? [sheet.primaryContact, ...(sheet?.alternateContacts || [])]
      : sheet?.alternateContacts || [];

    setValue(
      'alternateContacts',
      (sheetAlternateContacts?.length && sheetAlternateContacts) || ['']
    );

    setCurrContractorContacts(
      _.uniqBy(
        [...sheetAllFullContacts, ...(contractor?.contacts || []), ...(sheet?.contacts || [])],
        '_id'
      ) || [] // sheet?.contacts: if contact was saved into WL
    );

    if (sheet.locations && sheet.locations.length) {
      setSheetLocations(sheet.locations.slice());
      setValue('locations', sheetLocations);
    }
    if (sheet.rallyPoints && sheet.rallyPoints.length) {
      setSheetRallyPoints(sheet.rallyPoints.slice());
      setValue('rallyPoints', sheetRallyPoints);
    }

    const hours = (sheet.forceAccountTime || {}).hours;
    const mins = (sheet.forceAccountTime || {}).minutes;
    setTimeout(() => {
      setValue('forceAccountTime.hours', hours || 0);
      setValue('forceAccountTime.minutes', mins || minutes[0]);
    }, 0);
  }, [allContractors, allProjects, sheet]);

  useEffect(() => {
    setValue('locations', sheetLocations);
    setValue('rallyPoints', sheetRallyPoints);
  }, [sheetLocations, sheetRallyPoints]);

  const setDate = (value) => {
    setValue('createdAt', startOfDay(parse(value, dateFormat, new Date())));
  };

  const setGridDate = (value) => {
    // const [day, month, year] = value.split('/');
    setValue('gridDate', value);
  };

  const formatLocationName = (location) => {
    const a = location.address;
    const b = location.zipcode ? `, ${location.zipcode}` : '';
    const c = location.name ? ` - ${location.name}` : '';
    return `${a}${b}${c}`;
  };

  const onSubmit = async (values) => {
    if (errors && Object.keys(errors).length) return;

    const data = values;
    data.createdAt = new Date(data.createdAt).toISOString();
    data.locations = data?.locations?.filter((loc) => !!loc);
    data.rallyPoints = data?.rallyPoints?.filter((loc) => !!loc);
    data.addLocations = [
      ...projectLocations.filter((el) => el.new),
      ...projectRallyPoints.filter((el) => el.new),
    ];
    data.primaryContact = data?.alternateContacts.filter((loc) => !!loc)[0] || '';
    data.alternateContacts = data?.alternateContacts?.filter((loc) => !!loc)?.slice(1) || [];
    data.addContacts = currContractorContacts.filter((el) => el.new);
    data.gridDate = format(parse(data.gridDate, dateFormat, new Date()), globalBEDateFormat);

    try {
      await dispatch(patchWorklogGeneralInfo(data, sheet._id));
      closeDrawer();
      dispatch(updateSheetAddResource(sheet._id, 1));
    } catch (e) {
      setInfoModal({ isOpen: true, text: e.message });
      console.log(e);
    }
  };

  const handleSelectContractor = (e) => {
    const id = e.target.value;
    setValue('contractorId', id);
    const contractorProjects = allProjects.filter((project) => project.contractorId === id);

    const contractor = contractors.find((contractor) => {
      return contractor._id === id;
    });

    setValue('alternateContacts', ['']);
    setCurrContractorContacts([...(contractor?.contacts || []), ...(sheet?.contacts || [])]);

    setProjects(contractorProjects);
    setValue('projectId', '');
    setError('projectId', '');
    setProjectLocations([]);
    setProjectRallyPoints([]);
    setSheetLocations(['']);
    setSheetRallyPoints(['']);
  };

  const handleSelectProject = (e) => {
    const id = e.target.value;
    setValue('projectId', id);
    const errorAction = id ? clearErrors : setError;
    errorAction('projectId');

    const project = projects.find((project) => project._id === id);
    const nextLocations =
      project && project.locations && Array.isArray(project.locations) ? project.locations : [];
    const nextRallyPoints =
      project && project.rallyPoints && Array.isArray(project.rallyPoints)
        ? project.rallyPoints
        : [];
    setProjectLocations(_.uniqBy([...nextLocations, ...(sheet?.sheetLocations || [])], '_id'));
    setProjectRallyPoints(
      _.uniqBy([...nextRallyPoints, ...(sheet?.sheetRallyPoints || [])], '_id')
    );

    setSheetLocations(['']);
    setSheetRallyPoints(['']);
  };

  const handleAddContact = () => {
    if (currContractorContacts.length > getValues().alternateContacts.length) {
      setValue('alternateContacts', [...getValues().alternateContacts, '']);
    }
  };

  const handleShowAddContactBtn = () => {
    if (currContractorContacts.length > getValues().alternateContacts.length) {
      setShowAddContactBtn(true);
    } else {
      setShowAddContactBtn(false);
    }
  };

  const handleSelectLocation = (index) => (e) => {
    const id = e.target.value;
    if (id === 'add') {
      handleLocationEditor(index);
      return;
    }
    setSheetLocations((prevSheetLocations) => {
      prevSheetLocations[index] = id;
      return prevSheetLocations.slice();
    });
  };
  const handleSelectRallyPoint = (index) => (e) => {
    const id = e.target.value;
    if (id === 'add') {
      handleRallyPointEditor(index);
      return;
    }
    setSheetRallyPoints((prevSheetRallyPoint) => {
      prevSheetRallyPoint[index] = id;
      return prevSheetRallyPoint.slice();
    });
  };

  const handleLocationEditor = (value) => {
    if (value === false) {
      setLocationEditor(value);
      return;
    }
    setLocationEditor(value.toString());
  };

  const handleRallyPointEditor = (value) => {
    if (value === false) {
      setRallyPointEditor(value);
      return;
    }
    setRallyPointEditor(value.toString());
  };

  const handleRemoveLocation = (index) => () => {
    const isItAlreadyDeletedFromProj = deletedAllLocations.current?.deletedLocations?.some(
      (el) => el._id === sheetLocations[index]
    );
    if (isItAlreadyDeletedFromProj) {
      setProjectLocations((prev) => prev.filter((el) => el._id != sheetLocations[index]));
    }
    if (sheetLocations.length === 1) {
      setSheetLocations(['']);
    } else {
      setSheetLocations((prevSheetLocations) => {
        prevSheetLocations.splice(index, 1);
        return prevSheetLocations.slice();
      });
    }
  };
  const handleRemoveRallyPoints = (index) => () => {
    const isItAlreadyDeletedFromProj = deletedAllLocations.current?.deletedRallyPoints?.some(
      (el) => el._id === sheetRallyPoints[index]
    );
    if (isItAlreadyDeletedFromProj) {
      setProjectRallyPoints((prev) => prev.filter((el) => el._id != sheetRallyPoints[index]));
    }
    if (sheetRallyPoints.length === 1) {
      setSheetRallyPoints(['']);
    } else {
      setSheetRallyPoints((prevRallyPoints) => {
        prevRallyPoints.splice(index, 1);
        return prevRallyPoints.slice();
      });
    }
  };

  const handleAppendLocation = () => {
    if (projectLocations.length > sheetLocations.length) {
      setSheetLocations((prevSheetLocations) => [...prevSheetLocations, '']);
    }
  };
  const handleAppendRallyPoints = () => {
    if (projectRallyPoints.length > sheetRallyPoints.length) {
      setSheetRallyPoints((prevSheetRallyPoints) => [...prevSheetRallyPoints, '']);
    }
  };

  const closeModal = () => setInfoModal(defaultModalState);
  const closeOverlappingModal = () => setOverlappingModal(null);

  const forceAccount = watch('forceAccount');

  const getProjectDocs = () => {
    return [
      ...(allProjects?.find((proj) => proj._id === getValues()?.projectId)?.documentStorage || []),
    ].sort((a, b) => a?.name?.localeCompare(b?.name));
  };

  const now = new Date();
  const isFieldTechnician = checkUserRole('Field Technician');
  const calendarStartOfWeek = isFieldTechnician
    ? format(subDays(startOfWeek(now, { weekStartsOn: weekDayFromO }), 1), dateFormat)
    : null;
  const calendarEndOfWeek = isFieldTechnician
    ? format(addDays(endOfWeek(now, { weekStartsOn: weekDayFromO }), 1), dateFormat)
    : null;

  return (
    <div className={resourcesFormClasses.formWrapper} role="presentation">
      {!Object.keys(sheet).length ? (
        <PageLoader loading>
          <div />
        </PageLoader>
      ) : (
        <PageLoader loading={false} small>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={resourcesFormClasses.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '26px' }}>
                  Edit sheet
                </Typography>

                <IconButton
                  className={resourcesFormClasses.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={4}>
                  <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                    <label htmlFor="contractorId">
                      <Typography variant="body1" color="textSecondary">
                        Contractor:
                      </Typography>
                      <TextField
                        select
                        id="contractorId"
                        error={Boolean(errors.contractorId)}
                        className={sheetDetailsClasses.textField}
                        variant="outlined"
                        onChange={handleSelectContractor}
                        value={getValues().contractorId || ''}
                        inputProps={{ 'aria-label': 'Contractor' }}
                        rules={{
                          required: "This field can't be blank",
                        }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={0} value="" />
                        {!!contractors?.length &&
                          contractors.map((contractor) => (
                            <Option key={contractor._id} value={contractor._id}>
                              {contractor.name}
                            </Option>
                          ))}
                      </TextField>
                      {errors.contractorId ? (
                        <div className={sheetDetailsClasses.error}>{errors.contractorId}</div>
                      ) : null}
                    </label>
                  </Grid>
                  <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                    <label htmlFor="forceAccount">
                      <Typography variant="body1" color="textSecondary">
                        Time & Material:
                      </Typography>
                      <Controller
                        name="forceAccount"
                        control={control}
                        defaultValue=""
                        as={
                          <TextField
                            select
                            id="forceAccount"
                            className={sheetDetailsClasses.textField}
                            variant="outlined"
                            inputProps={{ 'aria-label': 'Time & Material' }}
                            SelectProps={{
                              native: isIOS,
                            }}
                            fullWidth
                          >
                            <Option key={0} value="No">
                              No
                            </Option>
                            <Option key={1} value="Full">
                              Full
                            </Option>
                            <Option key={2} value="Partial">
                              Partial
                            </Option>
                          </TextField>
                        }
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                    <label htmlFor="createdAt">
                      <Typography variant="body1" color="textSecondary">
                        Date:
                      </Typography>
                      <Controller
                        name="createdAt"
                        control={control}
                        defaultValue=""
                        as={({ value }) => {
                          return (
                            <DatePickerInput
                              selectedDay={value}
                              setFieldValue={(_, value) => setDate(value)}
                              field="createdAt"
                              fullWidth
                              sm
                              disabled={
                                sheet.submittedAt || sheet.workers.length || sheet.equipment.length
                              }
                              minDate={calendarStartOfWeek}
                              maxDate={calendarEndOfWeek}
                            />
                          );
                        }}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                    <label htmlFor="gridDate">
                      <Typography variant="body1" color="textSecondary">
                        Grid view date:
                      </Typography>
                      <Controller
                        name="gridDate"
                        control={control}
                        defaultValue=""
                        as={({ value }) => {
                          return (
                            <DatePickerInput
                              selectedDay={value}
                              setFieldValue={(_, value) => setGridDate(value)}
                              field="gridDate"
                              alignRight
                              fullWidth
                              sm
                              minDate={calendarStartOfWeek}
                              maxDate={calendarEndOfWeek}
                            />
                          );
                        }}
                      />
                    </label>
                  </Grid>
                  {forceAccount === 'Partial' && (
                    <>
                      <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                        <label htmlFor="forceAccountTime">
                          <Typography variant="body1" color="textSecondary">
                            T&M hours:
                          </Typography>
                          <Controller
                            name="forceAccountTime.hours"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: () => {
                                const values = getValues();
                                if (
                                  !/^[0-9]+$/i.test(
                                    values.forceAccountTime && values.forceAccountTime.hours
                                  )
                                ) {
                                  return 'The number is invalid. The field can contain figures only';
                                } else if (
                                  (+values.forceAccountTime &&
                                    +values.forceAccountTime.hours > 48) ||
                                  (+values.forceAccountTime && +values.forceAccountTime.hours < 0)
                                ) {
                                  return 'Must be a number from 0 to 48';
                                }
                                return true;
                              },
                            }}
                            as={
                              <TextField
                                id="forceAccountTime"
                                className={sheetDetailsClasses.textField}
                                error={Boolean(errors.forceAccountTime)}
                                variant="outlined"
                                inputProps={{
                                  'aria-label': 'Time & Material hours',
                                }}
                                fullWidth
                              />
                            }
                          />
                          {errors.forceAccountTime && errors.forceAccountTime.hours && (
                            <div className={sheetDetailsClasses.error}>
                              {errors.forceAccountTime.hours.message}
                            </div>
                          )}
                        </label>
                      </Grid>
                      <Grid item xs={12} lg={6} className={sheetDetailsClasses.zeroPaddingTop}>
                        <label htmlFor="forceAccountMinutes">
                          <Typography variant="body1" color="textSecondary">
                            T&M minutes:
                          </Typography>
                          <Controller
                            name="forceAccountTime.minutes"
                            control={control}
                            defaultValue={minutes[0]}
                            as={
                              <TextField
                                select
                                id="forceAccountMinutes"
                                className={sheetDetailsClasses.textField}
                                variant="outlined"
                                inputProps={{
                                  'aria-label': 'Time & Material minutes',
                                }}
                                SelectProps={{
                                  native: isIOS,
                                }}
                                fullWidth
                              >
                                {minutes.map((value, index) => (
                                  <Option key={index} value={value}>
                                    {value}
                                  </Option>
                                ))}
                              </TextField>
                            }
                          />
                        </label>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                    <label htmlFor="projectId">
                      <Typography variant="body1" color="textSecondary">
                        Project:
                      </Typography>
                      <TextField
                        select
                        id="projectId"
                        name="projectId"
                        error={Boolean(errors.projectId)}
                        className={sheetDetailsClasses.textField}
                        onChange={handleSelectProject}
                        value={getValues().projectId || ''}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Project' }}
                        SelectProps={{
                          native: isIOS,
                        }}
                        fullWidth
                      >
                        <Option key={0} value="" />
                        {!!projects?.length &&
                          projects
                            .sort((a, b) => a.route.localeCompare(b.route))
                            .map((project) => (
                              <Option key={project._id} value={project._id}>
                                {getProjectName(project, { jobNumber: true })}
                              </Option>
                            ))}
                      </TextField>
                      {errors.projectId && (
                        <div className={sheetDetailsClasses.error}>This field can't be blank</div>
                      )}
                    </label>
                  </Grid>
                  <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                    <Divider />
                  </Grid>
                  {!!getProjectDocs()?.length && (
                    <>
                      <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                        <Typography variant="body1" color="textSecondary">
                          Documents:
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          className={classnames(sheetDetailsClasses.zeroPaddingTop)}
                        >
                          {getProjectDocs()?.length === 1 ? (
                            <span>
                              <Link
                                variant="body1"
                                className={sheetDetailsClasses.docLinks}
                                href={getProjectDocs()[0].url}
                                target="_blank"
                              >
                                {getProjectDocs()[0].name}
                              </Link>
                              &nbsp;
                            </span>
                          ) : (
                            <TextField
                              select
                              id="documentStorageId"
                              name="documentStorageId"
                              className={sheetDetailsClasses.textFieldSelect}
                              variant="outlined"
                              onChange={(e) => {
                                e.preventDefault();
                              }}
                              label={'Select the document to open'}
                              inputProps={{
                                shrink: true,
                                'aria-label': 'Shift',
                              }}
                              SelectProps={{
                                displayEmpty: true,
                              }}
                              fullWidth
                              size="small"
                              value={''}
                            >
                              {getProjectDocs().map((doc) => (
                                <MenuItem
                                  key={doc.name}
                                  value={doc.name}
                                  onClick={(e) => {
                                    window.open(doc.url, '_black', 'noreferrer');
                                  }}
                                >
                                  {doc.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                        <Divider />
                      </Grid>
                    </>
                  )}
                  <AlternateContacts
                    getValues={getValues}
                    setValue={setValue}
                    sheetDetailsClasses={sheetDetailsClasses}
                    setCurrContractorContacts={setCurrContractorContacts}
                    currContractorContacts={currContractorContacts}
                    control={control}
                    CloseIcon={CloseIcon}
                  />

                  {showAddContactBtn && (
                    <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                      <div className={sheetDetailsClasses.primaryAdd} onClick={handleAddContact}>
                        <span>+ Add Alternate Contact</span>
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                    <Divider />
                  </Grid>
                  {sheetRallyPoints &&
                    sheetRallyPoints.map((locationId, index) => (
                      <Grid
                        item
                        xs={12}
                        className={sheetDetailsClasses.zeroPaddingTop}
                        key={locationId + index}
                      >
                        <label htmlFor={`rallyPoints_${index}`}>
                          <Typography variant="body1" color="textSecondary">
                            Rally Point{sheetRallyPoints.length === 1 ? null : ` ${index + 1}`}:
                          </Typography>
                          <div className={sheetDetailsClasses.flexBox}>
                            <TextField
                              select
                              id={`rallyPoints_${index}`}
                              value={locationId}
                              name={`rallyPoints[${index}]`}
                              onChange={handleSelectRallyPoint(index)}
                              className={sheetDetailsClasses.textField}
                              variant="outlined"
                              inputProps={{ 'aria-label': 'Rally Point' }}
                              SelectProps={{
                                native: isIOS,
                              }}
                              fullWidth
                              ref={register}
                            >
                              <Option key={'001'} value="" />
                              {projectRallyPoints
                                .filter(
                                  (location) =>
                                    !sheetRallyPoints.find((loc) => location._id === loc) ||
                                    location._id === locationId
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((location) => (
                                  <Option key={`${location._id}_${index}`} value={location._id}>
                                    {formatLocationName(location)}
                                  </Option>
                                ))}
                              {getValues().projectId && (
                                <Option
                                  key={'add'}
                                  value={'add'}
                                  className={sheetDetailsClasses.primary}
                                >
                                  + Create New Rally Point
                                </Option>
                              )}
                            </TextField>
                            <IconButton
                              className={sheetDetailsClasses.deleteLocationButton}
                              onClick={handleRemoveRallyPoints(index)}
                              aria-label="Delete"
                              disableTouchRipple
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </label>
                      </Grid>
                    ))}
                  <Grid item xs={12} className={sheetDetailsClasses.zeroPadding}>
                    {rallyPointEditor ? (
                      <LocationCreator
                        locationEditor={rallyPointEditor}
                        values={getValues()}
                        handleLocationEditor={handleRallyPointEditor}
                        setSheetRallyPoints={setSheetRallyPoints}
                        setFieldValue={setValue}
                        setLocations={setProjectRallyPoints}
                        type={'rallyPoint'}
                        fieldName={'rallyPoints'}
                        sheet={sheet}
                      />
                    ) : null}
                  </Grid>
                  {sheetRallyPoints &&
                    projectRallyPoints.length > sheetRallyPoints.length &&
                    !rallyPointEditor && (
                      <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                        <Button
                          variant="text"
                          className={sheetDetailsClasses.primaryTextButton}
                          onClick={handleAppendRallyPoints}
                          disableRipple
                        >
                          + Add Rally Point
                        </Button>
                      </Grid>
                    )}
                  <Grid container spacing={4} className={sheetDetailsClasses.paddingBottomBig}>
                    <Grid item xs={11} style={{ marginLeft: '15px' }}>
                      <Divider />
                    </Grid>
                  </Grid>
                  {sheetLocations &&
                    sheetLocations.map((locationId, index) => (
                      <Grid
                        item
                        xs={12}
                        className={sheetDetailsClasses.zeroPaddingTop}
                        key={locationId + index}
                      >
                        <label htmlFor={`locations_${index}`}>
                          <Typography variant="body1" color="textSecondary">
                            Project Location{sheetLocations.length === 1 ? null : ` ${index + 1}`}:
                          </Typography>
                          <div className={sheetDetailsClasses.flexBox}>
                            <TextField
                              select
                              id={`locations_${index}`}
                              value={locationId}
                              name={`locations[${index}]`}
                              onChange={handleSelectLocation(index)}
                              className={sheetDetailsClasses.textField}
                              variant="outlined"
                              inputProps={{ 'aria-label': 'Location' }}
                              SelectProps={{
                                native: isIOS,
                              }}
                              fullWidth
                              ref={register}
                            >
                              <Option key={'003'} value="" />
                              {projectLocations
                                .filter(
                                  (location) =>
                                    !sheetLocations.find((loc) => location._id === loc) ||
                                    location._id === locationId
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((location) => (
                                  <Option key={`${location._id}_${index}`} value={location._id}>
                                    {formatLocationName(location)}
                                  </Option>
                                ))}
                              {getValues().projectId && (
                                <Option
                                  key={'add'}
                                  value={'add'}
                                  className={sheetDetailsClasses.primary}
                                >
                                  + Create New Project Location
                                </Option>
                              )}
                            </TextField>
                            <IconButton
                              className={sheetDetailsClasses.deleteLocationButton}
                              onClick={handleRemoveLocation(index)}
                              aria-label="Delete"
                              disableTouchRipple
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </label>
                      </Grid>
                    ))}
                  <Grid item xs={12} className={sheetDetailsClasses.zeroPadding}>
                    {locationEditor ? (
                      <LocationCreator
                        locationEditor={locationEditor}
                        values={getValues()}
                        handleLocationEditor={handleLocationEditor}
                        setSheetRallyPoints={setSheetLocations}
                        setFieldValue={setValue}
                        setLocations={setProjectLocations}
                        type={'projectLocation'}
                        fieldName={'locations'}
                        sheet={sheet}
                      />
                    ) : null}
                  </Grid>
                  {sheetLocations &&
                    projectLocations.length > sheetLocations.length &&
                    !locationEditor && (
                      <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                        <Button
                          variant="text"
                          className={sheetDetailsClasses.primaryTextButton}
                          onClick={handleAppendLocation}
                          disableRipple
                        >
                          + Add Project Location
                        </Button>
                      </Grid>
                    )}
                </Grid>

                <div className={sheetFormClasses.buttonHolder}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={sheetFormClasses.cancelButton}
                    onClick={closeDrawer}
                    disableTouchRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    className={sheetFormClasses.saveButton}
                    disableTouchRipple
                    disabled={errors && !!Object.keys(errors).length}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </PageLoader>
      )}
      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeModal} />
      )}
      {overlappingModal && (
        <OverlappingModal
          data={overlappingModal.data}
          message={overlappingModal.reason}
          onClose={closeOverlappingModal}
        />
      )}
    </div>
  );
};

GeneralInfoForm.propTypes = {
  closeDrawer: propTypes.func.isRequired,
  sheet: propTypes.object.isRequired,
};

export default GeneralInfoForm;
