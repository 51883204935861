import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PageLoader from 'components/PageLoader';
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { isIOS, Option } from 'components/Option/Option';
import { subscriptions } from '../../constants';
import styles from './styles';
import Divider from '@material-ui/core/Divider';
import ClosesIcon from 'assets/images/close-icon.svg';
import cn from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '../AddClientForm/components/Tooltip';
import { TOOLTIP_TEXT } from '../AddClientForm/components/tooltips-text';
import { MEASUREMENT_SYSTEM } from 'common/measurements';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';

const validation = (values) => {
  const requiredField = "This field can't be blank";
  const senderEmail = 'Domain name is incorrect, please use @3208tech.com';
  const errors = {};
  if (!values.name.trim()) {
    errors.name = requiredField;
  }

  if (!values.settings?.emailSender?.trim()) {
    errors.emailSender = requiredField;
  }

  if (values.settings?.emailSender?.trim()) {
    if (
      !values.settings.emailSender.match(/.*(@3208tech\.com)$|^(materialcerts@guidemark\.info)$/)
    ) {
      errors.emailSender = senderEmail;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.settings.emailSender)) {
      errors.emailSender = 'Invalid email address';
    }
    if (values.settings.emailSender > 50) {
      errors.emailSender = 'The field cannot contain more than 50 symbols. Please, check';
    }
    if (!/^\S*$/.test(values.settings.emailSender)) {
      errors.emailSender = 'No spaces allowed';
    }
  }
  return errors;
};

const EditClientForm = ({ classes, closeDrawer, client, editClient }) => {
  const handleNotificationChange = useCallback(
    (i, setFieldValue, fieldName) => (e) => {
      const { value } = e.target;
      setFieldValue(`settings.emailReceivers[${i}][${fieldName}]`, value.trim());
    },
    []
  );
  const handleEmailRemoving = (values, i, setFieldValue) => () => {
    const nextValue = values.settings.emailReceivers.filter((_, index) => index !== i);
    setFieldValue('settings.emailReceivers', nextValue);
  };
  const handleAddEmail = (values, setFieldValue) => () => {
    setFieldValue('settings.emailReceivers', [
      ...values?.settings?.emailReceivers.filter((notificationObject) => notificationObject.email),
      { name: '', email: '' },
    ]);
  };

  if (!client) return '';

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={client}
        enableReinitialize={true}
        onSubmit={(values) => {
          let filteredData = null;
          if (values?.settings?.emailReceivers) {
            filteredData = values.settings.emailReceivers.filter((receiver) => !!receiver.email);
          } else {
            filteredData = [];
          }
          editClient(client._id, {
            ...values,
            settings: { ...values.settings, emailReceivers: filteredData },
          });
        }}
      >
        {({ values, handleChange, errors, touched, handleBlur, setFieldValue }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Edit Company
                </Typography>
                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="name" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Company name:
                      </Typography>
                      <TextField
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={Boolean(errors.name) && Boolean(touched.name)}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'name' }}
                        fullWidth
                        placeholder="Enter name"
                        SelectProps={{
                          native: isIOS,
                        }}
                      />
                      {errors.name && touched.name ? (
                        <div className={classes.error}>{errors.name}</div>
                      ) : null}
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="subscription" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Subscription:
                      </Typography>
                      <TextField
                        select
                        id="subscription"
                        name="subscription"
                        value={values.subscription}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Subscription' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {subscriptions.map((el) => (
                          <Option key={el} value={el}>
                            {el}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="active" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Status:
                      </Typography>
                      <TextField
                        select
                        id="active"
                        name="active"
                        value={values.active}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Status' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={'active'} value={true}>
                          Active
                        </Option>
                        <Option key={'inactive'} value={false}>
                          Inactive
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="weekStart" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Week Shift:
                      </Typography>
                      <TextField
                        select
                        id="settings.weekStart"
                        name="settings.weekStart"
                        value={values.settings?.weekStart || 'Sun'}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'weekStart' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={'Sun'} value={'Sun'}>
                          Sun - Sat
                        </Option>
                        <Option key={'Mon'} value={'Mon'}>
                          Mon - Sun
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="generateCertificates" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Material Certificates:
                      </Typography>
                      <TextField
                        select
                        id="settings.generateCertificates"
                        name="settings.generateCertificates"
                        value={values.settings.generateCertificates || false}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'generateCertificates' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={'true'} value={true}>
                          Yes
                        </Option>
                        <Option key={'false'} value={false}>
                          No
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="dateFormat" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Date and Time Format:
                      </Typography>
                      <TextField
                        defaultValue={`${client.settings?.dateFormat?.toUpperCase()} ${
                          client.settings?.timeFormat?.slice(0, 2) +
                          ' ' +
                          client.settings?.timeFormat?.slice(2)
                        }s`}
                        disabled={true}
                        onChange={handleChange}
                        className={classes.disabledFormat}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'dateFormat' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      ></TextField>
                    </label>
                  </Grid>
                </Grid>
                <Divider className={classes.marginVertical} />
                <Typography variant="h4" className={classes.marginSubHeader}>
                  Email Notifications:
                </Typography>
                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={12}>
                    <label htmlFor="name" className={cn(classes.labelEmail, classes.label)}>
                      <Typography variant="body1" color="textSecondary">
                        Receiver:
                      </Typography>
                    </label>
                    <div className={classes.actionsWrapper}>
                      <Button
                        variant="text"
                        className={classes.primaryTextButton}
                        onClick={handleAddEmail(values, setFieldValue)}
                        disableRipple
                      >
                        <AddIcon fontSize="small" /> Add receiver email
                      </Button>
                      {values.settings.emailReceivers.map((notificationObject, index) => (
                        <Grid
                          key={index}
                          container
                          spacing={1}
                          className={classes.emailBlockWrapper}
                        >
                          <Grid item xs={11} lg={5}>
                            <TextField
                              id={`settings.emailReceivers[${index}].name`}
                              name={`settings.emailReceivers[${index}].name`}
                              type="text"
                              value={
                                values?.settings?.emailReceivers &&
                                values.settings?.emailReceivers[index]?.name
                              }
                              className={classes.textField}
                              onChange={handleNotificationChange(index, setFieldValue, 'name')}
                              variant="outlined"
                              inputProps={{
                                'aria-label': `settings.emailReceivers[${index}].name`,
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={11} lg={6}>
                            <TextField
                              id={`settings.emailReceivers[${index}].email`}
                              name={`settings.emailReceivers[${index}].email`}
                              type="email"
                              value={
                                values?.settings?.emailReceivers &&
                                values.settings?.emailReceivers[index]?.email
                              }
                              className={classes.textField}
                              onChange={handleNotificationChange(index, setFieldValue, 'email')}
                              variant="outlined"
                              inputProps={{
                                'aria-label': `settings.emailReceivers[${index}].email`,
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={1} lg={1}>
                            <span
                              className={classes.removeEmail}
                              onClick={handleEmailRemoving(values, index, setFieldValue)}
                            >
                              <img src={ClosesIcon} alt="delete" />
                            </span>
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="name" className={classes.label}>
                      <Tooltip title={'Sender:'} text={TOOLTIP_TEXT.senderText} />
                    </label>
                    <Grid item xs={12} lg={12}>
                      <TextField
                        id={'settings.emailSender'}
                        name={'settings.emailSender'}
                        type="text"
                        value={values.settings?.emailSender || ''}
                        error={
                          Boolean(errors?.emailSender) && Boolean(touched?.settings?.emailSender)
                        }
                        onBlur={handleBlur}
                        className={classes.textField}
                        onChange={handleChange}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sender' }}
                        fullWidth
                      />
                      {errors?.emailSender && touched?.settings?.emailSender ? (
                        <div className={classes.error}>{errors.emailSender}</div>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.marginVertical} />
                <Grid container>
                  <Grid container item xs={12} lg={12} alignItems="center">
                    <Typography variant="h4">Measurement System: <span className={classes.headerDesc}>(by default)</span></Typography>
                  </Grid>
                  <Grid container item xs={12} lg={12}>
                    <RadioGroup
                      id={'settings.measurement'}
                      name={'settings.measurement'}
                      className={classes.radio}
                      onChange={handleChange}
                      value={values?.settings.measurement}
                      row
                    >
                      <FormControlLabel
                        value={MEASUREMENT_SYSTEM.imperial}
                        control={<CustomRadioButton big={'true'} />}
                        className={classes.radioItem}
                        label="Imperial System"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value={MEASUREMENT_SYSTEM.metric}
                        control={<CustomRadioButton big={'true'} />}
                        label="Metric System"
                        labelPlacement="end"
                        className={classes.radioItem}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Divider className={classes.marginVertical} />
                <Typography variant="h4" className={classes.marginSubHeader}>
                  Overtime Parameter:
                </Typography>
                <Grid container spacing={2} className={classes.flexContainer}>
                  {/* <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="settings.dayWorkTime" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Hours per Day:
                      </Typography>
                      <TextField
                        id="settings.dayWorkTime"
                        name="settings.dayWorkTime"
                        type="number"
                        value={values?.settings?.dayWorkTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        error={Boolean(errors.settings?.dayWorkTime) && Boolean(touched.settings?.dayWorkTime)}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'settings.dayWorkTime' }}
                        fullWidth
                        placeholder="Enter overtime hours per day"
                        SelectProps={{
                          native: isIOS,
                        }}
                      />
                      {errors.settings?.dayWorkTime && touched.settings?.dayWorkTime ? (
                        <div className={classes.error}>{errors.name}</div>
                      ) : null}
                    </label>
                  </Grid> */}
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="settings.weekWorkTime" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Hours per Week:
                      </Typography>
                      <TextField
                        id="settings.weekWorkTime"
                        name="settings.weekWorkTime"
                        type="number"
                        value={values.settings?.weekWorkTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        error={Boolean(errors.settings?.weekWorkTime) && Boolean(touched.settings?.weekWorkTime)}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'settings.weekWorkTime' }}
                        fullWidth
                        placeholder="Enter overtime hours per week"
                        SelectProps={{
                          native: isIOS,
                        }}
                      />
                      {errors.settings?.weekWorkTime && touched.settings?.weekWorkTime ? (
                        <div className={classes.error}>{errors.settings?.weekWorkTime}</div>
                      ) : null}
                    </label>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>
                <div className={classes.verticalDivider} />
                <Button type="submit" color="primary" className={classes.saveButton}>
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(EditClientForm);
