import axios from 'axios';
import $api from 'http/index';
import currentWorklogActions from './actions';
import { getOptions } from '../../helpers/getOptions';
import { updateSheets } from 'store/schedule/schedulePageOperation';

export const fetchWorklogSheet = (_sheetId) => async (dispatch) => {
  try {
    const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/sheets/${_sheetId}`, {
      ...getOptions(),
    });
    dispatch(currentWorklogActions.setWorklogSheet(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const patchWorklogGeneralInfo = (data, sheetId) => async (dispatch) => {
  try {
    const res = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/general
    `,
      data,
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.setWorklogSheet(res.data));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const putWorklogWeatherlInfo = (data, sheetId) => async (dispatch) => {
  try {
    const res = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/weather
    `,
      data,
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.setWorklogSheet(res.data));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const putWorklogNotes = (data, sheetId) => async (dispatch) => {
  try {
    const res = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/notes
    `,
      data,
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.setWorklogSheet(res.data));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const postWorklogComment = (data, sheetId) => async (dispatch) => {
  try {
    const res = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/note-comments
    `,
      data,
      {
        ...getOptions(),
      }
    );
    // dispatch(currentWorklogActions.newNotesComment(res.data));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const putEditWorklogComment = (data, sheetId, commentId) => async (dispatch) => {
  try {
    const res = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/note-comments/${commentId}
    `,
      data,
      {
        ...getOptions(),
      }
    );
    // dispatch(currentWorklogActions.editNotesComment(res.data));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const deleteWorklogComment = (sheetId, commentId) => async (dispatch) => {
  try {
    await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/note-comments/${commentId}
    `,
      {
        ...getOptions(),
      }
    );
    // dispatch(currentWorklogActions.deleteNotesComment(commentId));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const deleteWorklogAllResources = (sheetId) => async (dispatch) => {
  try {
    const res = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/clear-resources`,
      {},
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.setWorklogSheet(res.data));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const putWorklogEquipment = (data, sheetId) => async (dispatch) => {
  // try {
    const res = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/equipment`,
      data,
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.setWorklogSheet(res.data));
  // }
  //  catch (e) {
  //   throw new Error(e.response.data.message);
  // }
};

export const putWorklogWorkers = (data, sheetId) => async (dispatch) => {
  const res = await $api.put(`${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/workers`, data, {
    ...getOptions(),
  });
  dispatch(currentWorklogActions.setWorklogSheet(res.data));
};

export const patchWorklogJobTime = (data, sheetId) => async (dispatch) => {
  const res = await $api.patch(
    `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/job-time
      `,
    data,
    {
      ...getOptions(),
    }
  );
  dispatch(currentWorklogActions.setWorklogSheet(res.data));
};

export const postWorklogCL = (data, sheetId, workerId) => async (dispatch) => {
  const postData = data;
  try {
    const res = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/workers/${workerId}/crew-leader
      `,
      postData,
      {
        ...getOptions(),
      }
    );
    // dispatch(currentWorklogActions.toggleCL(workerId));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const putWorklogDayHours = (data, sheetId, workerId, isDuplicate) => async (dispatch) => {
  const res = await $api.put(
    `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/workers/${workerId}/day-hours
      `,
    data,
    {
      ...getOptions(),
    }
  );
  if (!isDuplicate) {
    //? on worklog > duplicate - we don't need to refresh page/worklog
    dispatch(currentWorklogActions.setWorklogSheet(res.data));
    dispatch(updateSheets());
  }
};

export const putEquipmentWorklogDayHours = (data, sheetId, equipment, isDuplicate) => async (dispatch) => {
  const res = await $api.put(
    `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/equipment/${equipment}/day-hours
      `,
    data,
    {
      ...getOptions(),
    }
  );
  if (!isDuplicate) {
    //? on worklog > duplicate - we don't need to refresh page/worklog
    dispatch(currentWorklogActions.setWorklogSheet(res.data));
    dispatch(updateSheets());
  }
};

export const removeWorkerFromWorklog = (sheetId, workerId) => async (dispatch) => {
  try {
    await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/workers/${workerId}`,
      getOptions()
    );
    dispatch(currentWorklogActions.removeWorker(workerId));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};


export const removeEquipmentFromWorklog = (sheetId, equipId) => async (dispatch) => {
  try {
    await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/equipment/${equipId}`,
      getOptions()
    );
    dispatch(currentWorklogActions.removeEquipment(equipId));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const getTimeoffs = (sheetId) => async (dispatch) => {
  try {
    const res = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/timeoffs?bySheetId=${sheetId}
      `,
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.setWorklogTimeOffs(res.data?.timeoffs));
  } catch (e) {
    console.log(e);
  }
};

export const patchCancelWorklog = (sheetId, data) => async (dispatch) => {
  const res = await axios.patch(
    `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/cancel`,
    data,
    getOptions()
  );

  dispatch(currentWorklogActions.setWorklogSheet(res.data));
};

export const patchUncancelWorklog = (sheetId) => async (dispatch) => {
  const res = await axios.patch(
    `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/uncancel`,
    {},
    getOptions()
  );

  dispatch(currentWorklogActions.setWorklogSheet(res.data));
};

export const fetchWorklogMaterials = (_sheetId) => async (dispatch) => {
  dispatch(currentWorklogActions.getWorklogMaterialsRequest());
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_sheetId}/materials`,
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.getWorklogMaterialsSuccess(response.data));
  } catch (e) {
    console.error(e);
  }
};

export const fetchAddWorklogMaterial = (_sheetId, data) => async (dispatch) => {
  dispatch(currentWorklogActions.postWorklogMaterialRequest());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_sheetId}/materials`,
      data,
      {
        ...getOptions(),
      }
    );
    // dispatch(currentWorklogActions.postWorklogMaterialSuccess(response.data));
    dispatch(
      currentWorklogActions.isPostWorklogMaterialSuccessfully({ type: true, message: null })
    );
  } catch (error) {
    console.log(error.response);
    dispatch(
      currentWorklogActions.isPostWorklogMaterialSuccessfully({
        type: false,
        message: error.response.data.message,
      })
    );
  }
};

export const fetchUpdateWorklogMaterial = (_sheetId, sheetMaterialId, data) => async (dispatch) => {
  dispatch(currentWorklogActions.updateWorklogMaterialRequest());
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_sheetId}/materials/${sheetMaterialId}`,
      data,
      {
        ...getOptions(),
      }
    );
    // dispatch(currentWorklogActions.updateWorklogMaterialSuccess(response.data));
    dispatch(
      currentWorklogActions.isPostWorklogMaterialSuccessfully({ type: true, message: null })
    );
  } catch (error) {
    console.log(error.response);
    dispatch(
      currentWorklogActions.isPostWorklogMaterialSuccessfully({
        type: false,
        message: error.response.data.message,
      })
    );
  }
};

export const fetchDeleteWorklogMaterial = (_sheetId, sheetMaterialId) => async (dispatch) => {
  dispatch(currentWorklogActions.deleteWorklogMaterialRequest());
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_sheetId}/materials/${sheetMaterialId}`,
      {
        ...getOptions(),
      }
    );
    // dispatch(currentWorklogActions.deleteWorklogMaterialSuccess({ _id: sheetMaterialId }));
  } catch (error) {}
};

export const fetchDuplicateWorklogMaterial = (_sheetId, sheetMaterialId) => async (dispatch) => {
  dispatch(currentWorklogActions.duplicateWorklogMaterialRequest());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_sheetId}/materials/${sheetMaterialId}/duplicate`,
      {},
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.duplicateWorklogMaterialSuccess(response.data));
  } catch (error) {}
};

export const patchSurfaceWorklogMaterial = (_sheetId, body) => async (dispatch) => {
  dispatch(currentWorklogActions.patchWorklogMaterialRequest());
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_sheetId}/materials/surface`,
      body,
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.patchWorklogMaterialSuccess(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const patchLocationWorklogMaterial = (_sheetId, body) => async (dispatch) => {
  dispatch(currentWorklogActions.patchWorklogMaterialRequest());
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_sheetId}/materials/location`,
      body,
      {
        ...getOptions(),
      }
    );
    dispatch(currentWorklogActions.patchWorklogMaterialSuccess(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const deleteAllWorklogMaterials = () => (dispatch) => {
  dispatch(currentWorklogActions.deleteAllWorklogMaterials());
};

export const getAllSurfaces =
  (filters = {}, worklogIdHeader) =>
  async (dispatch) => {

    const options = {
      params: {
        ...filters,
      },
      headers: {
        ...getOptions().headers,
      }
    };

  if (worklogIdHeader) options.headers['x-worklog-id'] = worklogIdHeader;

    try {
      const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/surfaces`, options);
      dispatch(currentWorklogActions.setWorklogSurfaces(res.data));
    } catch (e) {
      console.log(e);
    }
  };

export const addSurface = (data, worklogIdHeader) => async (dispatch) => {
  try {
    const options = {
      ...getOptions()
    };

    if (data?.sheetId) options.headers['x-worklog-id'] = data?.sheetId;
    if (worklogIdHeader) options.headers['x-worklog-id'] = worklogIdHeader;

    const result = await $api.post(`${process.env.REACT_APP_BASE_URL}/surfaces`, data, options);
    dispatch(currentWorklogActions.setCreatedWlSurface(result.data))
  } catch (e) {
    console.log(e);
  }
};

export const updateSurface = (surfaceId, data) => async (dispatch) => {
  try {
    await $api.put(
      `${process.env.REACT_APP_BASE_URL}/surfaces/${surfaceId}
    `,
      data,
      getOptions()
    );
  } catch (e) {
    console.log(e);
  }
};

export const deleteSurface = (surfaceId) => async (dispatch) => {
  try {
    await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/surfaces/${surfaceId}
    `,
      getOptions()
    );
    dispatch(currentWorklogActions.deleteWorklogSurface(surfaceId));
  } catch (e) {
    console.log(e);
  }
};
