import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import _filter from 'lodash/filter';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
// import FiltersBar from '../FiltersBar';
import FiltersBar from 'components/FiltersBar';
import FiltersPanel from './FiltersPanel';
import MaterialCard from './MaterialCard';
import PageLoader from 'components/PageLoader2';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchInventory,
  fetchManufacturers,
  fetchMaterials,
  fetchDeleteInventory,
  fetchInventoryGlassBeads,
} from 'store/supplies/suppliesOperations';
import suppliesActions from 'store/supplies/suppliesActions';
import InfiniteScroll from 'react-infinite-scroller';
import { exportData, importData } from 'helpers/downloadFile';

const searchOptions = {
  shouldSort: true,
  keys: ['material.name', 'colorOrNumber', 'lotNumber', 'manufacturer.name', 'symbolOrWidth'],
};

const LIMIT = 20;

const Inventory = ({ onSelect, setSnackbar }) => {
  const dispatch = useDispatch();
  
  const inventoryData = useSelector((state) => state.supplies.inventory.data);
  // const loading = useSelector((state) => state.supplies.inventory.loading);
  const snackbar = useSelector((state) => state.supplies.inventory.snackBarInfo);
  const successfullyDeleted = useSelector((state) => state.supplies.inventory.successfullyDeleted);
  const manufacturersData = useSelector((state) => state.supplies.manufacturers.data);
  const manufactureSnackbar = useSelector((state) => state.supplies.manufacturers.snackBarInfo);
  const materialsSnackbar = useSelector((state) => state.supplies.materials.snackBarInfo);
  
  const [fuse, setFuse] = useState(null);
  const [filter, setFilter] = useState({
    colorOrNumber: 'All',
    manufacturerId: 'All',
    sortBy: 'material',
    sortOrder: 'asc',
  });
  const [deletingMaterial, setDeletingMaterial] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [drawer, setDrawer] = useState(null);
  const color = useMemo(() => {
    const colorsSet = new Set();
    inventoryData?.filter(inventory => inventory?.colorOrNumber)?.forEach(inventory => colorsSet.add(inventory?.colorOrNumber));
    return Array.from(colorsSet);
  }, [inventoryData]);
  const [limit, setLimit] = useState(LIMIT);

  useEffect(() => {
    setSnackbar(snackbar);
  }, [snackbar]);

  useEffect(() => {
    setSnackbar(manufactureSnackbar);
  }, [manufactureSnackbar]);

  useEffect(() => {
    setSnackbar(materialsSnackbar);
  }, [materialsSnackbar]);

  useEffect(() => {
    dispatch(fetchInventory());
    dispatch(fetchMaterials(null, true, false));
    dispatch(fetchManufacturers());
    dispatch(fetchInventoryGlassBeads());
  }, []);

  useEffect(() => {
    if (successfullyDeleted) {
      dispatch(suppliesActions.isDeleteInventorySuccess(false));
      dispatch(fetchInventoryGlassBeads());
    }
    setDeletingMaterial(null);
  }, [successfullyDeleted]);

  useMemo(() => {
    setFuse(new Fuse(inventoryData, searchOptions));
  }, [inventoryData]);

  const openDrawer = useCallback(
    (type) => () => {
      setDrawer({ isOpen: true, type });
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setDrawer({ ...drawer, isOpen: false });
  }, [drawer]);

  const onDelete = useCallback((material) => {
    setDeletingMaterial(material);
  }, []);

  const onDeletingConfirmed = useCallback(async () => {
    if (!deletingMaterial) {
      console.warn('Deleting material is empty');
      return;
    }

    await dispatch(fetchDeleteInventory(deletingMaterial._id));
    setSnackbar({
      text: 'Successfully Deleted!',
      type: 'success',
    });
  }, [deletingMaterial]);

  const onFilterCancel = useCallback(() => {
    setFilter({
      colorOrNumber: 'All',
      manufacturerId: 'All',
      sortBy: 'material',
      sortOrder: 'asc',
    });
    dispatch(fetchInventory());
    closeDrawer();
  }, []);

  const onUpdateFilter = useCallback((values) => {
    setFilter(values);
    dispatch(fetchInventory(values));
    closeDrawer();
  }, []);

  const onExportExcel = () => {
    exportData(
      'inventory/csv',
      {
        searchValue,
      },
      (type, text) => {
        setSnackbar({
          type,
          text,
        });
      }
    );
  };

  const onImportExcel = async (data) => {
    importData('inventory/csv', data, (type, text) => {
      setSnackbar({
        type,
        text,
      });
    }).then((e) => {
      dispatch(fetchInventory());
      dispatch(fetchInventoryGlassBeads());
    });
  };

  const searchedInventoryData = fuse && searchValue ? fuse.search(searchValue) : inventoryData;
  const hasMore = searchedInventoryData.length > limit;

  const loadMore = (page) => {
    if (hasMore) setLimit((prev) => prev + LIMIT);
  };

  return !searchedInventoryData ? (
    <PageLoader loading={true}>
      <div style={{ height: 100 }} />
    </PageLoader>
  ) : (
    <>
      <FiltersBar
        openDrawer={openDrawer}
        updateSearchValue={setSearchValue}
        isDynamicSearch={true}
        hasImport={true}
        hasExport={true}
        onExportData={onExportExcel}
        onImportData={onImportExcel}
      />
      {searchedInventoryData && (
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={<div key={0}>Loading ...</div>}
          threshold={300}
        >
          <Grid container spacing={2}>
            {searchedInventoryData.slice(0, limit).map((m) => (
              <Grid key={m._id} item xs={12} md={6} lg={3}>
                <MaterialCard data={m} onDelete={onDelete} onClick={() => onSelect(m)} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      )}
      {drawer && (
        <Drawer anchor="right" open={drawer.isOpen}>
          <FiltersPanel
            manufacturers={manufacturersData}
            colors={color}
            closeDrawer={closeDrawer}
            onCancel={onFilterCancel}
            filter={filter}
            setFilter={setFilter}
            onUpdate={onUpdateFilter}
          />
        </Drawer>
      )}
      {deletingMaterial && (
        <ConfirmDialog
          isOpen={!!deletingMaterial}
          onClose={() => setDeletingMaterial(null)}
          onSubmit={onDeletingConfirmed}
          text="Are you sure you want to delete this material? This action cannot be reversed."
          cancelBtn={'Cancel'}
          confirmBtn={'Delete'}
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};

Inventory.defaultProps = {
  onSelect: () => {},
};
export default Inventory;
