import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';

export const getRegisterTimeoffs = async (status, date, filter, searchValue) => {
  const queryParams = new URLSearchParams();
  queryParams.append('status', status);
  queryParams.append('from', date[0].toISOString());
  queryParams.append('to', date[1].toISOString());
  queryParams.append('userStats', 'true')
  if (filter && Object.keys(filter).length) {
    Object.entries(filter).map(([key, value]) => queryParams.append(key, value));
  }
  if (searchValue) {
    queryParams.append('searchValue', searchValue);
  }

  try {
    const res = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/timeoffs?${queryParams}`,
      getOptions()
    );
    return res.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
