import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MySchedule from './components/MySchedule';
import FullSchedule from './components/FullSchedule';
import WorklogDetails from '../../components/WorklogDetails/WorklogDetails';
import Drawer from '@material-ui/core/Drawer';
import SheetForm from '../Worklogs/components/SheetForm/SheetForm';
import ResourcesForm from '../../components/ResourcesForm/ResourcesForm';
import CommentsForm from '../../components/CommentsForm/CommentsForm';
import MaterialForm from '../../components/MaterialForm/MaterialForm';
import TimeForm from '../../components/WorklogDetails/components/TimeBlock/components/TimeForm/TimeForm';
import WeatherForm from '../../components/WorklogDetails/components/WeatherBlock/components/WeatherForm/WeatherForm';
import NotesForm from '../../components/WorklogDetails/components/NotesBlock/components/NotesForm/NotesForm';
import GeneralInfoForm from '../../components/WorklogDetails/components/GeneralInfoBlock/components/GeneralInfoForm/GeneralInfoForm';
import SnackBar from '../../components/SnackBar';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';
import styles from './styles';
import { hasPermissionsFor } from 'helpers/_helpers';

const WorkerSchedulePage = ({ classes }) => {
  const params = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { snackbar } = useSelector(state => state.workerSchedule);

  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => setTabValue(newValue);

  const [drawer, setDrawer] = useState({
    type: 'update',
    isOpen: false,
    sheet: {},
  });
  const [additionalDrawer, setAdditionalDrawer] = useState({
    type: 'resources',
    subType: 'people',
    isOpen: false,
    material: {},
  });

  const openSnackbar = (type, text) => dispatch(workerSchedulePageActions.setSnackbar({ isOpen: true, type, text }));
  const closeSnackbar = () => dispatch(workerSchedulePageActions.closeSnackbar());

  const closeDrawer = () => setDrawer((prev) => ({ ...prev, type: 'details', isOpened: false }));
  const openDrawer = (type, sheet = {}) => () => {
    setDrawer((prev) => ({ type, isOpen: true, sheet }));
  };

  const openAdditionalDrawer = (type, subType, material = {}) => () => {
    setAdditionalDrawer((prev) => ({ ...prev, type, subType, isOpen: true, material }));
  };
  const closeAdditionalDrawer = () => {
    setAdditionalDrawer((prev) => ({ ...prev, isOpen: false }));
  };

  const sheetId = params.sheetId

  const noWorklogAccessCallback = () => {
    navigate(`/schedule-user`)
  }
  const hasFullScheduleAccess = hasPermissionsFor('myScheduleFull');

  return (
    <div className={classes.root}>
      {hasFullScheduleAccess && <Tabs value={tabValue} onChange={handleChangeTab} className={classes.tabs}>
        <Tab label="My schedule" disableRipple />
        <Tab label="Full schedule" disableRipple />
      </Tabs>}
      {tabValue === 0 && <MySchedule openSnackbar={openSnackbar} />}
      {tabValue === 1 && hasFullScheduleAccess && <FullSchedule openSnackbar={openSnackbar} />}
      {sheetId && <WorklogDetails
        openSnackbar={openSnackbar}
        updateVisibleSheets={() => {}}
        openDrawer={openDrawer}
        openAdditionalDrawer={openAdditionalDrawer}
        updateDrawerFromRouter={closeDrawer}
        sheetIds={[]}
        hasMore={false}
        pageCount={0}
        isSchedule
        showIndividualStartTime={true}
        // noAccessCallback={noWorklogAccessCallback}
      />}
      <Drawer
        anchor="right"
        open={drawer.isOpen}
        ModalProps={{
          BackdropProps: {
            classes: {
              root: sheetId ? classes.backdropOpacity : null,
            },
          },
        }}
      >
        {drawer.type === 'update' && (
          <SheetForm closeDrawer={closeDrawer} type={drawer.type} sheet={drawer.sheet} openSnackbar={openSnackbar} />
        )}
      </Drawer>

      <Drawer anchor="left" open={drawer.type === 'details' && additionalDrawer.isOpen}>
        {additionalDrawer.type === 'resources' && (
          <ResourcesForm closeDrawer={closeAdditionalDrawer} type={additionalDrawer.subType} />
        )}
        {additionalDrawer.type === 'comments' && (
          <CommentsForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'material' && (
          <MaterialForm
            closeDrawer={closeAdditionalDrawer}
            openSnackbar={openSnackbar}
            type={additionalDrawer.subType}
            material={additionalDrawer.material}
          />
        )}
        {additionalDrawer.type === 'time' && (
          <TimeForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'weather' && <WeatherForm closeDrawer={closeAdditionalDrawer} />}
        {additionalDrawer.type === 'notes' && (
          <NotesForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'general' && <GeneralInfoForm closeDrawer={closeAdditionalDrawer} />}
      </Drawer>
      <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
    </div>
  );
};

WorkerSchedulePage.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(WorkerSchedulePage);
