import React, { memo, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import WorklogsList from '../WorklogsList';
import { sortAndMapFieldTech } from 'store/worklogs/worklogsPageOperations'
import { fetchMyScheduleSheets } from 'store/workerSchedule/workerSchedulePageOperations';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const MySchedule = memo(
  ({openSnackbar}) => {
    const dispatch = useDispatch();
    const { loading, mySchedule: { sheets, selectedDate } } = useSelector(state => state.workerSchedule);
    const { dateFormat } = useDateSettingsFormat();

    const [sheetsByDate, setSheetsByDate] = useState([]);
    const [date, setDate] = useState(new Date());

    const sheetsInView = useMemo(() => {
      return sheetsByDate?.length
        ? sheetsByDate.find(([formattedDate, sheets]) => formattedDate === format(date, dateFormat))?.[1]
        : []
    }, [sheetsByDate, date]);

    useEffect(() => {
      const getData = async () =>{
        dispatch(workerSchedulePageActions.setLoading(true));
        try {
          dispatch(fetchMyScheduleSheets());
        } catch (e) {
          openSnackbar('error', e.message);
        } finally {
          dispatch(workerSchedulePageActions.setLoading(false));
        }
      }
      getData()
    }, [selectedDate]);

    useEffect(() => {
      setSheetsByDate(sortAndMapFieldTech(sheets, dateFormat))
    }, [sheets]);


    return (
      <div>
        {loading ? (
          <LoadingSpinner height={40} margin={32} />
        ) : (
          <WorklogsList
            sheets={sheetsInView}
            isFullSchedule={false}
            openSnackbar={openSnackbar}
            setDate={setDate}
            selectedDate={date}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.loading === nextProps.loading
);

export default MySchedule;