import React from 'react';
import { format, addDays, subDays, startOfWeek, endOfWeek } from 'date-fns';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { GLOBAL_DATE_FORMAT, allDatesFormat } from 'common/dateFormatConfig';
import { useWeekStart } from 'hooks/useWeekStart';

import styles from './styles.js'

export const ArrowHeaderCalendar = ({ classes, selectedDate, setDate }) => {
  const { weekDayFromO } = useWeekStart();

  const updateDate = (type) => () => {
    let newDate = null;
    if (type === 'add') newDate = addDays(new Date(selectedDate), 1);
    if (type === 'sub') newDate = subDays(new Date(selectedDate), 1);
    setDate(newDate);
  };

  const canGoBack = () => {
    const initialDay = format(
      subDays(startOfWeek(new Date(), { weekStartsOn: weekDayFromO }), 1),
      allDatesFormat[2]
    );
    const selectedDay = format(new Date(selectedDate), allDatesFormat[2]);

    return initialDay !== selectedDay;
  };

  const canGoForward = () => {
    const lastDay = format(
      addDays(endOfWeek(new Date(), { weekStartsOn: weekDayFromO }), 1),
      allDatesFormat[2]
    );
    const selectedDay = format(new Date(selectedDate), allDatesFormat[2]);

    return lastDay !== selectedDay;
  };


  return (
    <Typography
      variant="h4"
      align="center"
      className={classnames(classes.regularFontWeight, classes.calendarWrapper)}
    >
      {canGoBack() && (
        <ArrowLeftIcon onClick={updateDate('sub')} className={classes.inlineBlock} />
      )}
      <span
        className={classnames(
          classes.inlineBlock,
          !canGoBack() && classes.marginLeft,
          !canGoForward() && classes.marginRight
        )}
      >
        {format(new Date(selectedDate), GLOBAL_DATE_FORMAT.fullDateWithDay)}
      </span>
      {canGoForward() && (
        <ArrowRightIcon onClick={updateDate('add')} className={classes.inlineBlock} />
      )}
    </Typography>
  )
}

export default withStyles(styles)(ArrowHeaderCalendar);