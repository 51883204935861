import $api from 'http/index';
import suppliesActions from './suppliesActions';
import { getOptions } from 'helpers/getOptions';

export const fetchMaterials =
  (data, isSkipDetails = false, populate = true) =>
  async (dispatch) => {
    const options = {
      headers: {
        'X-Auth-Token': localStorage.getItem('authorization-token'),
      },
    };

    const params = {
      sortBy: data?.sortBy,
      sortOrder: data?.sortOrder,
      unitType: data?.unitType,
      markingType: data?.markingType,
      measurement: data?.measurement,
      skipDetails: isSkipDetails,
      populate: populate
    }
    options.params = JSON.parse(JSON.stringify(params)); // to exclude undefined values

    dispatch(suppliesActions.getMaterialsRequest());
    try {
      const response = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/materials`,
        options
      );
      dispatch(suppliesActions.getMaterialsSuccess(response.data));
    } catch (error) {
      dispatch(
        suppliesActions.getMaterialsError({
          text: error.response.data.message,
          type: 'error',
          isOpen: true,
        })
      );
    }
  };

export const fetchDuplicateMaterial = (id, data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  // dispatch(suppliesActions.duplicateMaterialRequest());
  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/materials/${id}/duplicate`,
      data,
      options
    );

    dispatch(suppliesActions.duplicateMaterialSuccess(response?.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
    dispatch(suppliesActions.duplicateMaterialError(error.message));
  }
};

export const fetchAddMaterial = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  dispatch(suppliesActions.postMaterialRequest());
  try {
    const response = await $api.post(`${process.env.REACT_APP_BASE_URL}/materials`, data, options);
    dispatch(suppliesActions.postMaterialSuccess(response.data));
    dispatch(suppliesActions.isPostMaterialSuccessfully({ type: true, message: null }));
  } catch (error) {
    // if (error.statusCode > 299) throw new Error(error.message);
    dispatch(
      suppliesActions.isPostMaterialSuccessfully({
        type: false,
        message: error.response.data.message,
      })
    );
    dispatch(suppliesActions.postMaterialError(error.message));
  }
};

export const fetchUpdateMaterial = (_id, data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  dispatch(suppliesActions.updateMaterialRequest());
  try {
    const response = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/materials/${_id}`,
      data,
      options
    );
    dispatch(suppliesActions.updateMaterialSuccess(response.data));
    dispatch(suppliesActions.isPostMaterialSuccessfully({ type: true, message: null }));
  } catch (error) {
    // if (error.statusCode > 299) throw new Error(error.message);
    dispatch(
      suppliesActions.isPostMaterialSuccessfully({
        type: false,
        message: error.response.data.message,
      })
    );
    dispatch(suppliesActions.updateMaterialError(error.message));
  }
};

export const fetchDeleteMaterial = (_id) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  dispatch(suppliesActions.deleteMaterialRequest());
  try {
    await $api.delete(`${process.env.REACT_APP_BASE_URL}/materials/${_id}`, options);
    dispatch(suppliesActions.deleteMaterialSuccess(_id));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
    dispatch(suppliesActions.deleteMaterialError(error.message));
  }
};

export const fetchInventory = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  let qp = '';
  if (data) {
    qp = `&sortBy=${data.sortBy}&sortOrder=${data.sortOrder}&colorOrNumber=${data.colorOrNumber}&manufacturerId=${data.manufacturerId}`;
  }
  dispatch(suppliesActions.getInventoryRequest());
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/inventory?populate=true` + qp,
      options
    );
    dispatch(suppliesActions.getInventorySuccess(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(
      suppliesActions.getInventoryError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchAddInventory = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'Content-Type': 'multipart/form-data',
    },
  };

  const requestData = new FormData();
  requestData.append('data', data.data);
  if (data.bulkCert) {
    requestData.append('bulkCert', data.bulkCert);
  }
  dispatch(suppliesActions.postInventoryRequest());

  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/inventory`,
      requestData,
      options
    );
    dispatch(suppliesActions.postInventorySuccess(response.data));
    dispatch(suppliesActions.successfullyAdded(true));
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 400) {
      dispatch(suppliesActions.postInventoryError(true));
    }

    dispatch(
      suppliesActions.inventorySnackBarInfo({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchUpdateInventory = (id, data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'Content-Type': 'multipart/form-data',
    },
  };

  const requestData = new FormData();
  requestData.append('data', data.data);
  if (data.bulkCert) {
    requestData.append('bulkCert', data.bulkCert);
  }
  dispatch(suppliesActions.updateInventoryRequest());
  try {
    const response = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/inventory/${id}`,
      requestData,
      options
    );
    dispatch(suppliesActions.updateInventorySuccess(response.data));
    dispatch(suppliesActions.successfullyAdded(true));
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 400) {
      dispatch(suppliesActions.updateInventoryError(error.message));
    }
    if (error.response.status === 413) {
      dispatch(
        suppliesActions.inventorySnackBarInfo({
          text: 'Chosen Bulk Certificate is too large',
          type: 'error',
          isOpen: true,
        })
      );  
    }
    dispatch(
      suppliesActions.inventorySnackBarInfo({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchDeleteInventory = (_id) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  try {
    const response = await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/inventory/${_id}`,
      options
    );
    dispatch(suppliesActions.deleteInventorySuccess(_id));
    dispatch(suppliesActions.isDeleteInventorySuccess(true));
  } catch (error) {
    dispatch(
      suppliesActions.deleteInventoryError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchInventoryGlassBeads = () => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
    params: {
      hasInventory: false
    }
  };

  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/glass-beads`, options);

    dispatch(suppliesActions.getInventoryAvailableGlassBeadsSuccess(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(
      suppliesActions.getInventoryAvailableGlassBeadsError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

// ****** MANUFACTURERS ******

export const fetchManufacturers = (data) => async (dispatch) => {
  let qp = '';
  if (data) {
    qp = `?sortBy=${data.sortBy}&sortOrder=${data.sortOrder}`;
  }
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  dispatch(suppliesActions.getManufacturersRequest());
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/manufacturers` + qp,
      options
    );

    dispatch(suppliesActions.getManufacturersSuccess(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(
      suppliesActions.getManufacturersError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchAddManufacture = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  dispatch(suppliesActions.postManufactureRequest());
  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/manufacturers `,
      data,
      options
    );
    dispatch(suppliesActions.postManufactureSuccess(response.data));
    dispatch(suppliesActions.successfullyAddedManufacturer(true));
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(suppliesActions.validationManufactureError(true));
    }
    dispatch(
      suppliesActions.postManufactureError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchUpdateManufacture = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  dispatch(suppliesActions.updateManufactureRequest());

  try {
    const response = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/manufacturers/${data._id}`,
      data,
      options
    );

    dispatch(suppliesActions.updateManufactureSuccess(response.data));
    dispatch(suppliesActions.successfullyAddedManufacturer(true));
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(suppliesActions.validationManufactureError(true));
    }
    dispatch(
      suppliesActions.updateManufactureError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchDeleteManufacture = (_id) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  try {
    const response = await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/manufacturers/${_id}`,
      options
    );
    dispatch(suppliesActions.deleteManufactureSuccess(_id));
    dispatch(suppliesActions.isDeleteManufacturerSuccess(true));
  } catch (error) {
    console.log(error.response);
    dispatch(
      suppliesActions.deleteManufactureError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchGlassBeads = (data, hasInventory) => async (dispatch) => {
  let qp = '?';
  if (data) {
    qp += `&sortBy=${data.sortBy}&sortOrder=${data.sortOrder}`;
  }
  if (hasInventory) {
    qp += `&hasInventory=${hasInventory}`;
  }
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  dispatch(suppliesActions.getGlassBeadRequest());
  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/glass-beads` + qp, options);

    dispatch(suppliesActions.getGlassBeadSuccess(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(
      suppliesActions.getGlassBeadError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchAddGlassBead = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  dispatch(suppliesActions.postGlassBeadRequest());
  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/glass-beads`,
      data,
      options
    );
    dispatch(suppliesActions.postGlassBeadSuccess(response.data));
    dispatch(suppliesActions.successfullyAddedGlassBead(true));
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(suppliesActions.validationGlassBeadError(true));
    }
    dispatch(
      suppliesActions.postGlassBeadError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchUpdateGlassBead = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  dispatch(suppliesActions.updateGlassBeadRequest());

  try {
    const response = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/glass-beads/${data._id}`,
      data,
      options
    );

    dispatch(suppliesActions.updateGlassBeadSuccess(response.data));
    dispatch(suppliesActions.successfullyAddedGlassBead(true));
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(suppliesActions.validationGlassBeadError(true));
    }
    dispatch(
      suppliesActions.updateGlassBeadError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};

export const fetchDeleteGlassBead = (_id) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  try {
    await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/glass-beads/${_id}`,
      options
    );
    dispatch(suppliesActions.isDeleteGlassBeadSuccess(true));
    dispatch(suppliesActions.deleteGlassBeadSuccess(_id));
  } catch (error) {
    console.log(error.response);
    dispatch(
      suppliesActions.deleteGlassBeadError({
        text: error.response.data.message,
        type: 'error',
        isOpen: true,
      })
    );
  }
};


// ********** SURFACES **********


export const getAllSuppliesSurfaces =
  (filters = {}) =>
  async (dispatch) => {

    const options = {
      params: {
        ...filters,
      },
      headers: {
        ...getOptions().headers,
      }
    };

    try {
      const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/surfaces`, options);
      dispatch(suppliesActions.setSurfaces(res.data));
    } catch (e) {
      console.log(e);
    }
  };

export const addSuppliesSurface = (data) => async (dispatch) => {
  try {
    const result = await $api.post(`${process.env.REACT_APP_BASE_URL}/surfaces`, data, getOptions());
    dispatch(suppliesActions.addSurface(result.data))
  } catch (e) {
    let message = e.response?.data?.message || e.message;
    if (Array.isArray(message)) message = message.join('\n');
    
    throw new Error(message);
  }
};

export const updateSuppliesSurface = (surfaceId, data) => async (dispatch) => {
  try {
    const result = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/surfaces/${surfaceId}`,
      data,
      getOptions()
    );
    dispatch(suppliesActions.updateSurface(result.data))
  } catch (e) {
    let message = e.response?.data?.message || e.message;
    if (Array.isArray(message)) message = message.join('\n');
    
    throw new Error(message);
  }
};

export const deleteSuppliesSurface = (surfaceId) => async (dispatch) => {
  try {
    await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/surfaces/${surfaceId}`,
      getOptions()
    );
    dispatch(suppliesActions.deleteSurface(surfaceId));
  } catch (e) {
    console.log(e);
  }
};