import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import WorklogCard from './components/WorklogCard';
import styles from './styles';
import { format, parse } from 'date-fns';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { hasPermissionsFor, isMobileScreen } from 'helpers/_helpers';
import SheetForm from 'pages/Worklogs/components/SheetForm/SheetForm';
import { Drawer } from '@material-ui/core';
import CarouselCalendar from '../CarouselCalendar/CarouselCalendar';
import ArrowHeaderCalendar from '../ArrowHeaderCalendar/ArrowHeaderCalendar';

const WorklogsList = ({ classes, sheets, isFullSchedule, selectedDate, setDate, openSnackbar }) => {
  const { dateFormat } = useDateSettingsFormat();
  const [drawer, setDrawer] = useState({
    type: 'create',
    isOpen: false,
  });

  const openDrawer = (type) => () => {
    setDrawer({ type, isOpen: true });
  };

  const closeDrawer = () => {
    setDrawer({ type: 'create', isOpen: false });
  };

  const formatDate = (date) =>
    format(parse(date, dateFormat, new Date()), GLOBAL_DATE_FORMAT.fullDateWithDay);

  const isMobile = isMobileScreen()

  return (
    <>
      <Grid
        container
        justifyContent={'center'}
        style={{ fontSize: 20, margin: '20px 0 0' }}
      >
        {isMobile
          ? (<CarouselCalendar onDateSelected={setDate} />)
          : (<ArrowHeaderCalendar setDate={setDate} selectedDate={selectedDate} />)
        }
      </Grid>
      {(hasPermissionsFor('worklogsFullAccess') ||
        hasPermissionsFor('worklogsEdit') ||
        hasPermissionsFor('worklogsCreate')) && !isFullSchedule && (
        <Grid
          container
          spacing={1}
          justifyContent={'flex-end'}
          alignItems={'center'}
          wrap={'nowrap'}
        >
          <Grid item>
            <Button
              variant="text"
              color="secondary"
              className={classes.addButton}
              onClick={openDrawer('create')}
              disableTouchRipple
            >
              + New Worklog
            </Button>
          </Grid>
        </Grid>
      )}
      {sheets?.length ? (
        sheets.map((sheet) => (
          <Grid key={sheet._id} container spacing={1}>
            <Grid item xs={12} lg={3} xl="auto">
              <WorklogCard sheet={sheet} />
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography variant="h3" align="center" className={classes.noResults}>
          None
        </Typography>
      )}
      <Drawer
        anchor="right"
        open={drawer.isOpen}
        classes={{
          paper: classes.drawer,
        }}
      >
        <SheetForm
          closeDrawer={closeDrawer}
          type={drawer.type}
          sheet={null}
          openSnackbar={openSnackbar}
          defaultDate={selectedDate}
        />
      </Drawer>
    </>
  );
};

WorklogsList.propTypes = {
  classes: propTypes.object.isRequired,
  sheets: propTypes.array.isRequired,
  isFullSchedule: propTypes.bool.isRequired,
  selectedDate: propTypes.object,
  setDate: propTypes.func,
};

export default withStyles(styles)(WorklogsList);
