import { materialProperties } from 'api/data/material-properties.js';
import { rolePermissions } from 'common/permissions';
import store from 'store/store';
import _ from 'lodash';
import {
  startOfWeek,
  isAfter,
  getDaysInMonth,
  getISODay,
  startOfMonth,
  addDays,
  format,
  subMonths,
  endOfMonth,
  subDays,
  addMonths,
  parse,
  endOfWeek,
  eachDayOfInterval,
} from 'date-fns';
import { FORMATS } from 'helpers/formats';
import { v4 as uuidv4 } from 'uuid';
import { userTypes } from 'common/permissions/userTypes';

export const WEEK_START = {
  Mon: 1,
  Sun: 0,
};
export const WEEK_START_PREV_MONTH = {
  Mon: 1,
  Sun: 7,
};
export const WEEK_START_NEXT_MONTH = {
  Mon: 7,
  Sun: 6,
};
/**
 * @param {string}("Mon" | "Sun") weekStart
 */

export const getWeekStartDayNumO = (day) => {
  return day === 'Sun' ? 0 : 1;
};

export const getCurrentMonthDates = (currentDate, weekStart, dateFormat) => {
  const emptyArray = new Array(getDaysInMonth(currentDate)).fill(null);
  const currentMonthDates = emptyArray.map((x, i) => ({
    dayNumber: format(addDays(startOfMonth(currentDate), i), dateFormat),
    dayOfWeek: getISODay(addDays(startOfMonth(currentDate), i)),
    events: [],
    key: uuidv4(),
  }));
  // fill the start of array
  const prevMonth = subMonths(currentDate, 1);
  for (let i = 0; i < 7; i++) {
    if (currentMonthDates[0].dayOfWeek === WEEK_START_PREV_MONTH[weekStart]) break; // if it is already the beginning of the week - do not add days to the current month (1 - Mon, 7 - Sun)
    const prevDay = {
      dayNumber: format(subDays(endOfMonth(prevMonth), i), dateFormat),
      dayOfWeek: getISODay(subDays(endOfMonth(prevMonth), i)),
      events: [],
      key: uuidv4(),
    };
    currentMonthDates.unshift(prevDay);
  }
  // fill the end of array
  const nextMonth = addMonths(currentDate, 1);
  for (let i = 0; i < 7; i++) {
    if (
      currentMonthDates[currentMonthDates.length - 1].dayOfWeek === WEEK_START_NEXT_MONTH[weekStart]
    )
      break; // if it is already the end of the week - do not add days after the current month (7 - Mon, 6 - Sun)
    const nextDay = {
      dayNumber: format(addDays(startOfMonth(nextMonth), i), dateFormat),
      dayOfWeek: getISODay(addDays(startOfMonth(nextMonth), i)),
      events: [],
      key: uuidv4(),
    };
    currentMonthDates.push(nextDay);
  }
  return currentMonthDates;
};

export const getMonthDatesByFullWeeksAsKeys = (
  date,
  weekStart,
  formatString,
  onlyStartEnd = false
) => {
  const currentDate = date;

  const start = startOfMonth(currentDate); // Start of the current month
  const end = endOfMonth(currentDate); // End of the current month

  let startOfWeekDay = startOfWeek(start, { weekStartsOn: weekStart }); // First day of the week in the current month
  let endOfWeekDay = endOfWeek(end, { weekStartsOn: weekStart }); // Last day of the week in the current month

  // If the month starts on a day other than the start of the week, add days from the previous month at the beginning
  if (start.getDate() !== startOfWeekDay.getDate()) {
    const prevMonthEnd = subDays(start, 1);
    startOfWeekDay = startOfWeek(prevMonthEnd, { weekStartsOn: weekStart });
  }

  // If the end of the month is not the end of the week, add days from the next month at the end
  if (end.getDate() !== endOfWeekDay.getDate()) {
    const nextMonthStart = addDays(end, 1);
    endOfWeekDay = endOfWeek(nextMonthStart, { weekStartsOn: weekStart });
  }

  // Get an array of all days within the current month's week range
  const daysOfCurrentWeek = eachDayOfInterval({ start: startOfWeekDay, end: endOfWeekDay });

  if (onlyStartEnd) {
    return {
      startOfWeekDay,
      endOfWeekDay,
    };
  }
  // Convert the array of dates into an object where the keys are dates in the specified format
  const monthDatesAsKeys = daysOfCurrentWeek.reduce((acc, date) => {
    const formattedDate = format(date, formatString);
    acc[formattedDate] = true;
    return acc;
  }, {});

  return monthDatesAsKeys;
};

export const splitArrayToArraysOfRow = (array) => {
  const resultArray = [[], [], [], [], [], []];
  array.forEach((el, i) => {
    const k = Math.floor(i / 7);
    resultArray[k].push(el);
  });
  return resultArray;
};

/**
 *
 * @param {Project} project
 * @param {{jobNumber?: boolean}} [options]
 * @returns {string}
 */
export const getProjectName = function (project, options = {}) {
  if (project) {
    let projectName = project.route + (project.section ? ', Section ' + project.section : '');
    if (options.jobNumber) {
      projectName += project.jobNumber ? `, #${project.jobNumber}` : '';
    }
    return projectName;
  } else {
    return '';
  }
};

export const getContactName = (contact) => {
  let formattedString = `${contact.name}`;

  if (contact?.title) {
    formattedString += `, ${contact.title}`;
  }

  if (contact?.email) {
    formattedString += `, ${contact.email}`;
  }

  if (contact?.phoneNumber) {
    formattedString += `, ${contact.phoneNumber}`;
  }

  return formattedString;
};

export const compareByForeman = (a, b) => {
  return a.foreman === b.foreman ? 0 : a.foreman ? -1 : 1;
};

export const getEquipmentColorClass = (colorStr) => {
  const hexColors = colorStr.split('').reduce((arr, val, ind, colorArr) => {
    if (val === '#') return [];
    if (colorArr.length === 7) {
      const str = arr[((ind - 1) / 2) | 0] || '';
      arr[((ind - 1) / 2) | 0] = str + val;
    }

    return arr;
  }, []);

  const rgb = hexColors.map((hex) => parseInt(hex, 16));
  const rgbWeights = [0.299, 0.587, 0.114];
  const totalIntens = rgb.reduce((perc, color, index) => {
    return perc + color * rgbWeights[index];
  }, 0);

  return totalIntens > 143 ? 'text-black' : '';
};

export const formatWeatherToString = (weather) => {
  if (!weather) return '';
  let { temperature, humidity, notes } = weather;
  const resArr = [];
  const temperatureArray =
    temperature && temperature.length ? temperature.map((t) => t + String('\xB0') + 'F') : [];
  resArr.push(...temperatureArray);
  if (humidity) resArr.push('humidity ' + humidity + '%');
  if (notes) resArr.push(notes);
  return resArr.join(', ');
};

export const checkUserEditSheetPermissions = (sheet, weekDayFromO) => {
  const { _, getState } = store;

  const { user } = getState().personalProfile;
  const { fullUserPermissions } = getState().personalProfile;

  const requestedPermissions = ['worklogsCreate', 'worklogsEdit'];
  const submittedPermissions = ['worklogsEditSubmitted', 'worklogsFullAccess'];

  const permissionsByRole = {
    [userTypes.projectManagement]: ['scheduleEdit', 'scheduleFullAccess'],
    [userTypes.accounting]: ['scheduleFullAccess'],
  }
  const editAssignedPermissions = ['worklogsEditAssigned'];
  const editCreatedPermissions = ['worklogsEditCreated'];
  const editCLSubmittedPermissions = ['worklogsEditCLSubmitted'];
  const sheetWorkers = sheet.worker ? [sheet.worker] : sheet.workers;
  const isAssignedWorker =
    !!sheetWorkers.find((w) => w._id === user._id) &&
    checkUserPermissions(editAssignedPermissions, fullUserPermissions);
  const isOwner =
    sheet.owner === user._id && checkUserPermissions(editCreatedPermissions, fullUserPermissions);
  const isForemanWithinWeek =
    !!sheetWorkers.find((w) => w.foreman && w._id === user._id) &&
    isAfter(
      new Date(sheet.createdAtDate),
      startOfWeek(new Date(), { weekStartsOn: weekDayFromO })
    ) &&
    checkUserPermissions(editCLSubmittedPermissions, fullUserPermissions);
  const hasPermissionsByRole = permissionsByRole[user.profile?.role?.roleName]?.length &&
    checkUserPermissions(permissionsByRole[user.profile?.role?.roleName], user);

  const hasDefaultAccess =
    checkUserPermissions(requestedPermissions, fullUserPermissions) ||
    hasPermissionsByRole ||
    isAssignedWorker ||
    isOwner;
  const hasSubmittedAccess =
    checkUserPermissions(submittedPermissions, fullUserPermissions) || isForemanWithinWeek;

  if (sheet?.submittedAt) {
    return hasSubmittedAccess;
  }

  return hasDefaultAccess || hasSubmittedAccess;
};

export const checkUserPermissions = (requiredPermissions, userPermissions) => {
  if (requiredPermissions && userPermissions) {
    return requiredPermissions.some((permission) => userPermissions[permission]);
  }
};

export const hasPermissionsFor = (permission, checkboxValue, userType) => {
  const { _, getState } = store;
  const { user } = getState().personalProfile;

  if (user) {
    const userRole = user && user.profile.role;
    const permissionsList = {
      ...rolePermissions[userRole.roleName],
      ...userRole.extPermissions,
    };

    if (userRole.roleName === 'Dispatcher' && userType === 'Field Technician') {
      if (
        checkboxValue === 'remoteKiosk' ||
        checkboxValue === 'kioskModeMobile' ||
        checkboxValue === 'activeSchedule'
      ) {
        return permissionsList.usersFieldTechnicianToggle;
      }
      if (permission === 'usersBasicRead') {
        return true;
      }
    }

    return permissionsList[permission];
  } else {
    return false;
  }
};

export const checkUserRole = (role) => {
  const { _, getState } = store;
  const { user } = getState().personalProfile;

  if (!user) return false;
  const userRole = user.profile.role;
  return userRole.roleName === role;
};

export const cssColor = (color) => {
  return {
    White: 'white',
    Yellow: 'yellow',
    Black: 'black',
    Blue: 'blue',
    Red: 'red',
    Orange: 'orange',
    Green: 'green',
    Purple: 'purple',
  }[color];
};

export const pluralizeSymbol = (quantity, symbolName) => {
  const symbol = _.find(materialProperties.symbols, { name: symbolName });
  return symbol ? symbol.name + (quantity !== 1 ? symbol.plural : '') : symbolName;
};

export const changeDateFormatFromTo = (fromDateFormat, toDateFormat, date) => {
  const formattedDay = format(parse(date, fromDateFormat, new Date()), toDateFormat);
  return formattedDay;
};
export const isMobileSchedule = () => window.innerWidth <= 700;
export const isMobileScreen = () => window.innerWidth <= 700;
