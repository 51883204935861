import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  shortDate,
  getDateHours,
  isWithinCurrentWeek,
  getDateFromHours,
} from 'helpers/_date-helpers';
import PageLoader from 'components/PageLoader';
import AlertModal from 'components/AlertModal';
import SheetDetails from './components/SheetDetails';
import styles from './styles';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';
import { updateSheets } from 'store/schedule/schedulePageOperation';
import { generateIncrement } from 'helpers/generateIncrement';
import { isSelectedTimeValid, generateHoursObject, getHoursMinutes } from 'helpers/helpers';
import { fetchProjects } from 'store/projectTypes/projectTypesOperations';
import { fetchAllContractorsData } from 'store/contractors/contractorsPageOperations';
import { postNewWorklogSheet } from 'store/worklogs/worklogsPageOperations';
import { useSettings } from '../../../Schedule/components/ScheduleCalendar/components/UpdateModal/hooks/useSettings';
import { parse, startOfDay, format, add, startOfWeek, endOfWeek, subDays, addDays } from 'date-fns';
import { getContactName, checkUserRole } from 'helpers/_helpers';
import { CALENDAR_VIEW_TYPES } from 'pages/Schedule/components/ScheduleHeader/components/DateTypeSelector/constants';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { addFirstZero } from '../../../../helpers/helpers';
import {
  globalBEDateFormat,
  DAY_PART,
  convertTo24HourFormat,
  convertTimeObject,
} from 'common/dateFormatConfig';
import { generateIncrementDateCreator } from 'hooks/useTimeStates';
import { useWeekStart } from 'hooks/useWeekStart';
import { parseStringDate } from 'helpers/_date-helpers';

const validation = (values, getTimeFormatHook) => {
  const errors = {};
  const hoursValues = {
    dayStart: values.dayStart,
    hourStart: values.hourStart,
    minuteStart: values.minuteStart,
    amPmStart: values.amPmStart,
    dayEnd: values.dayEnd,
    hourEnd: values.hourEnd,
    minuteEnd: values.minuteEnd,
    amPmEnd: values.amPmEnd,
  };
  const { isValid, errorsOnFields } = isSelectedTimeValid(hoursValues, getTimeFormatHook);
  if (!isValid) {
    errors.dayStart = errorsOnFields.includes('dayStart');
    errors.hourStart = errorsOnFields.includes('hourStart');
    errors.minuteStart = errorsOnFields.includes('minuteStart');
    errors.amPmStart = errorsOnFields.includes('amPmStart');
    errors.dayEnd = errorsOnFields.includes('dayEnd');
    errors.hourEnd = errorsOnFields.includes('hourEnd');
    errors.minuteEnd = errorsOnFields.includes('minuteEnd');
    errors.amPmEnd = errorsOnFields.includes('amPmEnd');
  }

  if (!values.contractorId.trim()) {
    errors.contractorId = "This field can't be blank";
  }
  if (!values.projectId.trim()) {
    errors.projectId = "This field can't be blank";
  }
  if (values.notes.trim() && values.notes.trim().length > 150) {
    errors.notes = 'You riched the limit of symbols. Max is 150';
  }
  if (values.schedulerNotes.trim() && values.schedulerNotes.trim().length > 150) {
    errors.schedulerNotes = 'You riched the limit of symbols. Max is 150';
  }

  if (values.forceAccountHours.trim()) {
    if (!/^[0-9]+$/i.test(values.forceAccountHours)) {
      errors.forceAccountHours = 'The entry is invalid. The field is numeric only.';
    } else if (+values.forceAccountHours > 48 || +values.forceAccountHours < 0) {
      errors.forceAccountHours = 'Must be a number from 0 to 48';
    }
  }

  if (values.humidity.trim()) {
    if (!/^[0-9]+$/i.test(values.humidity)) {
      errors.humidity = 'The entry is invalid. The field is numeric only.';
    } else if (+values.humidity > 100 || +values.humidity < 0) {
      errors.humidity = 'Must be a number from 0 to 100';
    }
  }

  if (values.weatherNotes.trim() && values.weatherNotes.trim().length > 50) {
    errors.weatherNotes = 'You riched the limit of symbols. Max is 50';
  }

  if (values.travelTimeToHours.trim() && !/^[0-9]+$/i.test(values.travelTimeToHours)) {
    errors.travelTimeToHours = 'The entry is invalid. The field is numeric only.';
  } else if (+values.travelTimeToHours > 24 || +values.travelTimeToHours < 0) {
    errors.travelTimeToHours = 'Must be a number from 0 to 24';
  }

  if (values.travelTimeToMinutes.trim() && !/^[0-9]+$/i.test(values.travelTimeToMinutes)) {
    errors.travelTimeToMinutes = 'The entry is invalid. The field is numeric only.';
  } else if (+values.travelTimeToMinutes > 59 || +values.travelTimeToMinutes < 0) {
    errors.travelTimeToMinutes = 'Must be a number from 0 to 59';
  }

  //* travelTimeFrom

  if (values.travelTimeFromHours.trim() && !/^[0-9]+$/i.test(values.travelTimeFromHours)) {
    errors.travelTimeFromHours = 'The entry is invalid. The field is numeric only.';
  } else if (+values.travelTimeFromHours > 24 || +values.travelTimeFromHours < 0) {
    errors.travelTimeFromHours = 'Must be a number from 0 From 24';
  }

  if (values.travelTimeFromMinutes.trim() && !/^[0-9]+$/i.test(values.travelTimeFromMinutes)) {
    errors.travelTimeFromMinutes = 'The entry is invalid. The field is numeric only.';
  } else if (+values.travelTimeFromMinutes > 59 || +values.travelTimeFromMinutes < 0) {
    errors.travelTimeFromMinutes = 'Must be a number from 0 to 59';
  }

  //* rainPayHours
  if (values.rainPayHours.trim() && !/^[0-9]+$/i.test(values.rainPayHours)) {
    errors.rainPayHours = 'The entry is invalid. The field is numeric only.';
  } else if (
    values.rainPayHours.trim() &&
    (+values.rainPayHours > 24 || +values.rainPayHours < 0)
  ) {
    errors.rainPayHours = 'Must be a number from 0 From 24';
  }

  if (values.rainPayMinutes.trim() && !/^[0-9]+$/i.test(values.rainPayMinutes)) {
    errors.rainPayMinutes = 'The entry is invalid. The field is numeric only.';
  } else if (
    values.rainPayMinutes.trim() &&
    (+values.rainPayMinutes > 59 || +values.rainPayMinutes < 0)
  ) {
    errors.rainPayMinutes = 'Must be a number from 0 to 59';
  }

  return errors;
};

const SheetForm = ({
  sheet,
  type,
  classes,
  closeDrawer,
  openSnackbar,
  defaultDate,
  isDataLoading,
}) => {
  const settings = useSelector((store) => store.personalProfile?.organization?.settings);
  const timeFormat = useSelector(
    (store) => store.personalProfile.organization?.settings?.timeFormat
  );
  const { weekDayFromO } = useWeekStart();
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;
  const [contractors, setContractors] = useState([]);
  const [contractorAlternateContacts, setContractorAlternateContacts] = useState([]); //fully obj of contact
  const [projects, setProjects] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hours, setHours] = useState(
    generateIncrement(generateIncrementDateCreator({ maxValue: 12, step: 1, start: 1 }, timeFormat))
  );
  const [minutes, setMinutes] = useState(
    generateIncrement({ maxValue: 60, step: 5, padMaxLength: 2 })
  );
  const [dayPart, setDayPart] = useState(DAY_PART);
  const [days, setDays] = useState(['-1', '0', '1']);
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [locationEditor, setLocationEditor] = useState(false);
  const [rallyPointEditor, setRallyPointEditor] = useState(false);

  const formikRef = useRef();
  const dispatch = useDispatch();

  const allProjects = useSelector((store) => store.projectTypes.projects.data);
  const projectsLoading = useSelector((store) => store.projectTypes.projects.isLoading);
  const allContractors = useSelector((store) => store.contractors.data);
  const contractorsLoading = useSelector((store) => store.contractors.isLoading);
  const { calendarTypeView } = useSelector((store) => store.schedule);

  useEffect(() => {
    dispatch(
      fetchProjects({ status: 'Active' }, NaN, null, {
        create: type === 'create',
        id: type === 'update' && sheet._id,
      })
    );
    dispatch(
      fetchAllContractorsData({ create: type === 'create', id: type === 'update' && sheet._id })
    );
  }, []);

  useEffect(() => {
    if (!settings) return;
    formikRef?.current?.resetForm({ values: initialCreateValues });
  }, [settings]);

  useEffect(() => {
    const contractors = allContractors;

    if (type === 'update') {
      const contractorId = sheet.project.contractor.oldContractorId || sheet.contractorId;

      const projects = allProjects.find((project) => project.contractorId === contractorId);

      const locations = sheet.project.locations || [];

      setContractors(contractors);
      setProjects(projects);
      setLocations(locations);
      setIsLoading(false);
    } else {
      setContractors(contractors);
      setIsLoading(false);
    }
  }, [allProjects, allContractors]);

  const handleSelectContractor = (setFieldValue) => (e, value, reason) => {
    //todo//
    if (reason === 'blur') return;
    const id = reason === 'clear' ? '' : value._id;
    setFieldValue('contractorId', id);
    setFieldValue('locations', ['']);
    setFieldValue('documentStorageId', '');
    setDocuments([]);

    const currContractor = allContractors.find((el) => el._id === id);

    if (currContractor) {
      setContractorAlternateContacts(currContractor?.contacts || []);
    }
    if (!id) {
      setProjects([]);
      setContractorAlternateContacts([]);
      return;
    }

    const contractorProjects = allProjects.filter((project) => project.contractorId === id);
    setProjects(contractorProjects);
  };

  const handleSelectProject = (setFieldValue) => (e) => {
    setFieldValue('projectId', e.target.value);
    setFieldValue('locations', ['']);

    if (e.target.value) {
      const project = projects.find((project) => {
        return project._id === e.target.value;
      });
      setLocations([
        ...(project.locations || []).map((el) => ({ ...el, type: 'projectLocation' })),
        ...(project.rallyPoints || []).map((el) => ({ ...el, type: 'rallyPoint' })),
      ]);
      setDocuments(project?.documentStorage || []);
      setFieldValue('documentStorageId', '');
    } else {
      setLocations([]);
    }
  };

  const handleChangeTemperature = (setFieldValue, index, values, arrayHelpers) => (e) => {
    setFieldValue(`temperatures[${index}]`, e.target.value);
    if (values.temperatures.length === index + 1) {
      arrayHelpers.push('');
    }
    if (index >= 0 && e.target.value === '') {
      arrayHelpers.remove(index);
    }
  };

  const handleTemperatureFieldsKeyDown = (e) => {
    // if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8)) {
    //   e.preventDefault();
    // }
  };

  const getInitialStartTime = (field) => {
    if (!settings?.defaultDayTime) return null;
    const hourObj = convertTimeObject(settings.defaultDayTime, is12Format);
    return hourObj[field];
  };

  const getInitialOnJobTime = (field) => {
    if (!settings?.defaultDayTime) return null;
    let onJobHours = +settings.defaultDayTime.hour + 2;
    let onJobAmPm = settings.defaultDayTime.amPm;
    if (onJobHours === 12) {
      onJobAmPm = onJobAmPm === DAY_PART[0] ? DAY_PART[1] : DAY_PART[0];
    }
    if (onJobHours > 12) {
      onJobHours = onJobHours - 12;
      onJobAmPm = onJobAmPm === DAY_PART[0] ? DAY_PART[1] : DAY_PART[0];
    }
    const result = {
      hour: onJobHours,
      minute: settings.defaultDayTime.minute,
      amPm: onJobAmPm,
    };
    return result[field];
  };

  const handleChangeStartTimeHours = (setFieldValue, values) => (e) => {
    const is12HourFormat = is12Format;
    const startHours = +e.target.value;
    setFieldValue('startTimeHours', startHours);

    let adjustedHours = startHours + 2;

    let dayPart = type === 'update' ? values.amPmStart : values.onJobDayPart;
    let dayChange = 0;

    if (is12HourFormat) {
      if (adjustedHours >= 12) {
        dayPart =
          adjustedHours >= 12 && adjustedHours < 24
            ? dayPart === DAY_PART[0]
              ? DAY_PART[1]
              : DAY_PART[0]
            : dayPart;
        adjustedHours = adjustedHours > 12 ? adjustedHours - 12 : adjustedHours;
      }
    } else {
      if (adjustedHours >= 24) {
        adjustedHours -= 24;
        dayChange = 1;
      }
    }
    if (type === 'update') {
      setFieldValue('dayStart', dayChange);
      setFieldValue('hourStart', adjustedHours.toString());
      if (is12HourFormat) {
        setFieldValue('amPmStart', dayPart);
      }

      setFieldValue('dayEnd', dayChange);
      setFieldValue('hourEnd', adjustedHours.toString());
      if (is12HourFormat) {
        setFieldValue('amPmEnd', dayPart);
      }
    } else {
      setFieldValue('onJobHours', adjustedHours.toString());
      if (is12HourFormat) {
        setFieldValue('onJobDayPart', dayPart);
      }
    }
  };

  const handleChangeStartTimeMinutes = (setFieldValue) => (e) => {
    setFieldValue('startTimeMinutes', e.target.value);
    if (type === 'update') {
      setFieldValue('minuteStart', e.target.value);
      setFieldValue('minuteEnd', e.target.value);
    } else {
      setFieldValue('onJobMinutes', e.target.value);
    }
  };

  const handleChangeStartTimeDayPart = (setFieldValue, values) => (e) => {
    setFieldValue('startTimeDayPart', e.target.value);
    const shouldChangeAmPm = +values.startTimeHours === 10 || +values.startTimeHours === 11;
    const reverseAmPmValue = e.target.value === DAY_PART[0] ? DAY_PART[1] : DAY_PART[0];
    const calculatedAmPm = shouldChangeAmPm ? reverseAmPmValue : e.target.value;
    if (type === 'update') {
      const dayStart = shouldChangeAmPm && reverseAmPmValue === DAY_PART[0] ? 1 : 0;
      setFieldValue('amPmStart', calculatedAmPm);
      setFieldValue('amPmEnd', calculatedAmPm);
      setFieldValue('dayEnd', dayStart);
      setFieldValue('dayStart', dayStart);
    } else {
      setFieldValue('onJobDayPart', calculatedAmPm);
    }
  };

  const handleChangeShift = (setFieldValue, values) => (e) => {
    setFieldValue('timeOfDay', e.target.value);

    const defaultTime = e.target.value === 'Day' ? 'defaultDayTime' : 'defaultNightTime';
    if (!settings?.[defaultTime]) return;
    const { amPm, hour, minute } = convertTimeObject(settings[defaultTime], is12Format);
    handleChangeStartTimeHours(setFieldValue, values)({ target: { value: String(Number(hour)) } });
    handleChangeStartTimeMinutes(setFieldValue, values)({ target: { value: String(minute) } });
    handleChangeStartTimeDayPart(setFieldValue, values)({ target: { value: amPm } });
  };

  const handleSelectLocation = (setFieldValue, sheetLocations, index, type) => (e) => {
    const id = e.target.value;
    if (id === 'add') {
      if (type === 'projectLocation') {
        handleLocationEditor(index);
      } else {
        handleRallyPointEditor(index);
      }
      return;
    }
    const newSheetLocations = sheetLocations.slice();

    newSheetLocations[index] = id;
    if (type === 'projectLocation') {
      setFieldValue('locations', newSheetLocations);
    } else {
      setFieldValue('rallyPoints', newSheetLocations);
    }
  };

  const handleRemoveLocation = (setFieldValue, sheetLocations, index, type) => () => {
    const newSheetLocations = sheetLocations.slice();
    if (sheetLocations.length <= 1) {
      setFieldValue(type, ['']);
    } else {
      newSheetLocations.splice(index, 1);
      setFieldValue(type, newSheetLocations);
    }
  };

  const handleAppendLocation = (setFieldValue, sheetLocations, type) => () => {
    if (locations.length <= sheetLocations.length) {
      setFieldValue(type, ['']);
    } else {
      setFieldValue(type, [...sheetLocations, '']);
    }
  };

  const getInitialHoursOnUpdate = () => {
    const { hours, createdAt } = sheet;
    return generateHoursObject(hours.start, hours.end, createdAt, getTimeFormatHook);
  };

  const openInfoModal = (text) => {
    setInfoModal({ isOpen: true, text });
  };

  const closeInfoModal = () => {
    setInfoModal({ isOpen: false, text: '' });
  };

  const handleLocationEditor = (value) => {
    if (value === false) {
      setLocationEditor(value);
      return;
    }
    setLocationEditor(value.toString());
  };
  const handleRallyPointEditor = (value) => {
    if (value === false) {
      setRallyPointEditor(value);
      return;
    }
    setRallyPointEditor(value.toString());
  };

  const now = new Date();
  const isFieldTechnician = checkUserRole('Field Technician');
  const calendarStartOfWeek = isFieldTechnician
    ? format(subDays(startOfWeek(now, { weekStartsOn: weekDayFromO }), 1), dateFormat)
    : null;
  const calendarEndOfWeek = isFieldTechnician
    ? format(addDays(endOfWeek(now, { weekStartsOn: weekDayFromO }), 1), dateFormat)
    : null;

  const savedContractorId = sheet
    ? sheet.project.contractor.oldContractorId || sheet.contractorId
    : '';

  const initialCreateValues = {
    contractorId: '',
    projectId: '',
    forceAccount: 'No',
    timeOfDay: 'Day',
    forceAccountHours: '',
    forceAccountMinutes: '00',
    startDate: defaultDate || new Date(),
    gridDate: defaultDate || new Date(),
    locations: [''],
    rallyPoints: [''],
    alternateContacts: [''],
    notes: '',
    schedulerNotes: '',
    onJobHours: String(Number(getInitialOnJobTime('hour'))) || '2',
    onJobMinutes: getInitialOnJobTime('minute') || '00',
    onJobDayPart: getInitialOnJobTime('amPm') || DAY_PART[0],
    estimatedHours: '2',
    estimatedMinutes: '00',
    startTimeHours: String(Number(getInitialStartTime('hour'))) || '1',
    startTimeMinutes: getInitialStartTime('minute') || '00',
    startTimeDayPart: getInitialStartTime('amPm') || DAY_PART[0],
    travelTimeToHours: '',
    travelTimeToMinutes: '',
    travelTimeFromHours: '',
    travelTimeFromMinutes: '',
    rainPayHours: '',
    rainPayMinutes: '',
    rainPayDescription: '',
    travelTimeOnly: false,
    isRainPayEnabled: false,
    temperatures: [''],
    humidity: '',
    weatherNotes: '',
    documentStorageId: '',
  };

  const initialValues = sheet
    ? {
        // contractorId: Contractors.findOne(savedContractorId).active ? savedContractorId : '',
        contractorId: allContractors.find((contractor) => contractor._id === savedContractorId)
          ? savedContractorId
          : '',
        projectId: sheet.project.oldProjectId || sheet.projectId,
        forceAccount: sheet.forceAccount,
        forceAccountHours: (sheet.forceAccountTime && sheet.forceAccountTime.hours) || '',
        forceAccountMinutes: (sheet.forceAccountTime && sheet.forceAccountTime.minutes) || '00',
        gridDate:
          sheet.grid && sheet.grid.date
            ? parse(sheet.grid.date, dateFormat, new Date())
            : new Date(),
        locations: sheet.locations || [''],
        rallyPoints: sheet.rallyPoints || [''],
        alternateContacts: sheet?.alternateContacts || [],
        notes: sheet.notes || '',
        timeOfDay: sheet.timeOfDay,
        schedulerNotes: sheet.schedulerNotes || '',
        startTimeHours: sheet.startTime.hourStart,
        startTimeMinutes: sheet.startTime.minuteStart,
        startTimeDayPart: sheet.startTime.amPmStart,
        temperatures: (sheet.weather &&
          sheet.weather.temperature && [...sheet.weather.temperature, '']) || [''],
        humidity: (sheet.weather && sheet.weather.humidity) || '',
        weatherNotes: (sheet.weather && sheet.weather.notes) || '',
        travelTimeToHours: sheet.travelTime ? sheet.travelTime.hours : '',
        travelTimeToMinutes: sheet.travelTime ? sheet.travelTime.minutes : '',
        dayStart: getInitialHoursOnUpdate().dayStart,
        hourStart: getInitialHoursOnUpdate().hourStart,
        minuteStart: getInitialHoursOnUpdate().minuteStart,
        amPmStart: getInitialHoursOnUpdate().amPmStart,
        dayEnd: getInitialHoursOnUpdate().dayEnd,
        hourEnd: getInitialHoursOnUpdate().hourEnd,
        minuteEnd: getInitialHoursOnUpdate().minuteEnd,
        amPmEnd: getInitialHoursOnUpdate().amPmEnd,
      }
    : initialCreateValues;

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        innerRef={formikRef}
        validate={(values) => validation(values, getTimeFormatHook)}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        enableReinitialize={false}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          const data = {
            contractorId: values.contractorId,
            projectId: values.projectId,
            locations: values.locations.filter((el) => !!el),
            rallyPoints: values.rallyPoints.filter((el) => !!el),
            addLocations: locations.filter((el) => el.new),
            notes: values.notes,
            schedulerNotes: values.schedulerNotes,
            timeOfDay: values.timeOfDay,
            forceAccount: values.forceAccount,
            primaryContact: values.alternateContacts.filter((loc) => !!loc)[0],
            alternateContacts: values.alternateContacts?.filter((loc) => !!loc)?.slice(1) || [],
            addContacts: contractorAlternateContacts.filter((el) => el.new),
            forceAccountTime:
              values.forceAccount === 'Partial'
                ? {
                    hours: values.forceAccountHours,
                    minutes: values.forceAccountMinutes,
                  }
                : null,
            travelTimeOnly: values.travelTimeOnly,
            isRainPayEnabled: values.isRainPayEnabled,
            rainPay: {
              hours: values.rainPayHours || '0',
              minutes: values.rainPayMinutes || '0',
              description: values.rainPayDescription || '',
            },
            startTime: {
              hour: convertTo24HourFormat(values.startTimeHours, values.startTimeDayPart),
              minute: values.startTimeMinutes,
              // amPmStart: values.startTimeDayPart,
            },
            weather: {
              temperature: values.temperatures,
              humidity: values.humidity,
              notes: values.weatherNotes,
            },
          };
          if (!settings?.miscStipend) {
            delete data.isRainPayEnabled;
            delete data.rainPay;
          }

          if (type === 'create') {
            const createdAt =
              typeof values.startDate === 'string'
                ? startOfDay(parse(values.startDate, dateFormat, new Date()))
                : startOfDay(parseStringDate(values.startDate));
            const gridDate =
              typeof values.gridDate === 'string'
                ? format(parse(values.gridDate, dateFormat, new Date()), globalBEDateFormat)
                : format(parseStringDate(values.gridDate), globalBEDateFormat);
            const start = {
              amPm: values.onJobDayPart,
              hour: values.onJobHours,
              minute: values.onJobMinutes,
            };
            const convertedHours = convertTo24HourFormat(start.hour, start.amPm);
            const tmp = add(createdAt, {
              hours: convertedHours,
              minutes: start.minute,
            });
            data.hours = {
              start: tmp,
              end: add(tmp, {
                hours: values.estimatedHours,
                minutes: values.estimatedMinutes,
              }),
              travelTimeTo: {
                hours: values.travelTimeToHours || '0',
                minutes: values.travelTimeToMinutes || '0',
              },
              travelTimeFrom: {
                hours: values.travelTimeFromHours || '0',
                minutes: values.travelTimeFromMinutes || '0',
              },
            };
            if (values.travelTimeOnly) {
              delete data.hours.start;
              delete data.hours.end;
            }
            data.createdAt = createdAt;
            data.gridDate = gridDate;
            data.grid = {
              date: gridDate,
            };

            try {
              await postNewWorklogSheet(data);
              if (calendarTypeView != CALENDAR_VIEW_TYPES.monthly) {
                dispatch(updateSheets());
                dispatch(worklogsPageActions.setForceUpdate(true));
              }
              closeDrawer();
            } catch (e) {
              setSubmitting(false);
              openSnackbar('error', e.response.data.message);
            }
          } else {
            const gridDate =
              typeof values.gridDate === 'string'
                ? format(parse(values.gridDate, dateFormat, new Date()), dateFormat)
                : format(values.gridDate, dateFormat);
            const hoursValues = {
              dayStart: values.dayStart,
              hourStart: values.hourStart,
              minuteStart: values.minuteStart,
              amPmStart: values.amPmStart,
              dayEnd: values.dayEnd,
              hourEnd: values.hourEnd,
              minuteEnd: values.minuteEnd,
              amPmEnd: values.amPmEnd,
            };
            const hours = getDateFromHours(hoursValues, sheet.createdAt, getTimeFormatHook);
            data.hours = hours;
            data.grid = {
              date: gridDate,
            };

            // delete contractor and project ids if they aren't changed
            const isOldContractorId =
              data.contractorId === sheet.project.contractor.oldContractorId;
            const isOldProjectId = data.projectId === sheet.project.oldProjectId;
            if (sheet.published || sheet.submittedAt) {
              if (isOldContractorId) delete data.contractorId;
              if (isOldProjectId) delete data.projectId;
            }
          }
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
          <PageLoader loading={isSubmitting || isLoading || projectsLoading || contractorsLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '40px' }}>
                  {type === 'create' ? 'New Work Log' : 'Edit Sheet'}
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <SheetDetails
                  contractors={contractors}
                  projects={projects}
                  projectLocations={locations}
                  hours={hours}
                  minutes={minutes}
                  dayPart={dayPart}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleSelectContractor={handleSelectContractor}
                  handleSelectProject={handleSelectProject}
                  handleChangeTemperature={handleChangeTemperature}
                  type={type}
                  handleChangeStartTimeHours={handleChangeStartTimeHours}
                  handleChangeStartTimeMinutes={handleChangeStartTimeMinutes}
                  handleChangeStartTimeDayPart={handleChangeStartTimeDayPart}
                  handleChangeShift={handleChangeShift}
                  handleTemperatureFieldsKeyDown={handleTemperatureFieldsKeyDown}
                  days={days}
                  sheet={type === 'update' ? sheet : {}}
                  shortDate={shortDate}
                  handleSelectLocation={handleSelectLocation}
                  handleRemoveLocation={handleRemoveLocation}
                  handleAppendLocation={handleAppendLocation}
                  locationEditor={locationEditor}
                  rallyPointEditor={rallyPointEditor}
                  handleLocationEditor={handleLocationEditor}
                  handleRallyPointEditor={handleRallyPointEditor}
                  setLocations={setLocations}
                  contractorAlternateContacts={contractorAlternateContacts}
                  setContractorAlternateContacts={setContractorAlternateContacts}
                  documents={documents}
                  calendarStartOfWeek={calendarStartOfWeek}
                  calendarEndOfWeek={calendarEndOfWeek}
                />
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>

      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeInfoModal} />
      )}
    </div>
  );
};

SheetForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  sheet: propTypes.object,
  // isDataLoading: propTypes.bool.isRequired,
  // projects: propTypes.array.isRequired,
};

export default withStyles(styles)(SheetForm);
