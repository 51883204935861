import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import styles from './styles';
import { getProjectName } from 'helpers/_helpers';
import WorkersSection from './components/WorkersSection';
import EquipmentSection from './components/EquimpentSection';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTime } from 'common/dateFormatConfig';
import { useCanEditWorklog } from 'common/hooks/use-can-edit-worklog';

export const getInteractiveContactName = (contact, classes) => {
  let interactiveContactName = (
    <span>
      {contact.name}
      {contact?.title && `, ${contact.title}`}
      {contact?.email && (
        <a href={`mailto:${contact.email}`} className={classes.contactLink}>
          , {contact.email}
        </a>
      )}
      {contact?.phoneNumber && (
        <a href={`tel:${contact.phoneNumber}`} className={classes.contactLink}>
          , {contact.phoneNumber}
        </a>
      )}
    </span>
  );

  return interactiveContactName;
};

const WorklogCard = ({ classes, sheet }) => {
  const currentUser = useSelector((store) => store.personalProfile.user);
  const navigate = useNavigate();
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const hasAccess = useCanEditWorklog(sheet);

  const [isNoteOpenned, setIsNoteOpenned] = useState(false);
  const [canceledDate, setCanceledDate] = useState(null);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const { notes, canceledAt } = sheet;
    if (notes && notes.length <= 25) {
      setIsNoteOpenned(true);
    }
    if (canceledAt) {
      const date = format(new Date(canceledAt), dateFormat);
      setCanceledDate(date);
    }
  }, []);

  const toggleNotes = () => setIsNoteOpenned((prev) => !prev);

  const getTruncatedNotes = () => {
    const { notes } = sheet;
    if (notes.length > 25) {
      return notes.slice(0, 22) + '...';
    } else return notes;
  };

  const sheetLocation = () => {
    if (sheet.project) {
      const selectedSheetRallyPoints = sheet?.rallyPoints || [];
      const selectedSheetLocations = sheet?.locations || [];

      const allPlaces = [
        ...(sheet?.sheetRallyPoints || []),
        ...(sheet.project?.rallyPoints || []),
        ...(sheet?.sheetLocations || []),
        ...(sheet.project?.locations || []),
      ];

      const locations = [
        ...selectedSheetRallyPoints.map(
          (pointId) => allPlaces.find((el) => el._id === pointId) || ''
        ),
        ...selectedSheetLocations.map(
          (locationId) => allPlaces.find((el) => el._id === locationId) || ''
        ),
      ].filter((el) => !!el);

      if (_.isEmpty(locations)) {
        return '';
      }
      return locations && locations.length
        ? ', ' + locations.map((location) => location.name || location.address).join(', ')
        : '';
    } else {
      return '';
    }
  };

  const openWorklogDetails = () => {
    navigate(`/schedule-user/${sheet._id}`);
  };

  const preventAction = (e) => e.stopPropagation();

  const currentUserInSheet = sheet?.workers?.find((el) => el._id === currentUser?._id);

  const getStartTime = () => {
    return convertTime(
      currentUserInSheet?.startTime || sheet.startTime,
      getTimeFormatHook().is12Format
    );
  };

  return (
    <Card className={classes.card}>
      <Grid container className={classes.paddingBox}>
        <Grid item xs={12} className={classes.paddingBottom}>
          {!!sheet.canceledAt && (
            <Typography variant="h6" className={classes.redColor}>
              Canceled by {sheet.canceledByUser || ''} on {canceledDate}
            </Typography>
          )}
          <Typography
            variant="h6"
            className={classnames(
              classes.primaryColor
            )}
            onClick={openWorklogDetails}
          >
            {getProjectName(sheet.project)}
            {sheet.project.county ? `, ${sheet.project.county}` : ''}
            {sheetLocation()}
            {sheet.project.jobNumber && (
              <span className={classes.projectId} onClick={preventAction}>
                #{sheet.project.jobNumber}
              </span>
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary" className={classes.name}>
            {sheet.project.contractor && sheet.project.contractor.name}
          </Typography>
          <div className={classes.flexBox}>
            <div className={classes.inlineFlex}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.regularFontWeight}
              >
                Start time:
              </Typography>
              <Typography variant="body1" color="textPrimary" className={classes.marginLeft}>
                {getStartTime()}
              </Typography>
            </div>
            {sheet.forceAccount !== 'No' && (
              <div className={classes.flexWrapper}>
                <Typography variant="body2" className={classes.forceAccount}>
                  T&M: {sheet.forceAccount}
                </Typography>
              </div>
            )}
          </div>
        </Grid>

        {/* //* START OF CONTACTS BLOCK */}

        {sheet?.primaryContact && (
          <Grid item xs={12} lg={12}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(classes.regularFontWeight)}
            >
              Primary Contact:
            </Typography>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              placement="top"
              onClose={handleTooltipClose}
              open={open === sheet?.primaryContact}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied"
              selectable={true}
            >
              <Typography
                variant="body1"
                color="textPrimary"
                className={classnames(classes.paddingBottom)}
                selectable={true}
              >
                {getInteractiveContactName(sheet?.primaryContact, classes)}
              </Typography>
            </Tooltip>
          </Grid>
        )}
        {sheet.alternateContacts?.map((contact, i) => {
          return (
            <Grid item xs={12} lg={12}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight)}
              >
                Alternate Contact {sheet.alternateContacts.length < 1 ? null : i + 1}:
              </Typography>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                onClose={handleTooltipClose}
                open={open === contact}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied"
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classnames(classes.paddingBottom)}
                >
                  {getInteractiveContactName(contact, classes)}
                </Typography>
              </Tooltip>
            </Grid>
          );
        })}

        {/* //* END OF CONTACTS BLOCK */}

        {!!sheet.notes && (
          <Grid item xs={12} className={classes.paddingBottom}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
            >
              Notes:
            </Typography>
            <div
              className={classnames(classes.notesSection, isNoteOpenned && classes.columnDirection)}
            >
              <Typography variant="body1" className={classes.notes}>
                {isNoteOpenned ? sheet.notes : getTruncatedNotes()}
              </Typography>
              {sheet.notes.length > 25 && (
                <Button
                  variant="text"
                  disableRipple
                  className={classes.moreButton}
                  onClick={toggleNotes}
                >
                  {isNoteOpenned ? 'Less' : 'More'}
                </Button>
              )}
            </div>
          </Grid>
        )}

        <Grid item xs={12} className={classes.paddingBottom}>
          <Divider />
        </Grid>

        <WorkersSection sheet={sheet} />
        <EquipmentSection sheet={sheet} />
      </Grid>
    </Card>
  );
};

WorklogCard.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
};

export default withStyles(styles)(WorklogCard);
