import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const UserStatsTooltip = ({ classes, userStats }) => {
  const [isInfoOpen, setInfoOpen] = useState(false);

  return (
      <div
        className={classes.toolInfoIcon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setInfoOpen(true);
          setTimeout(() => {
            setInfoOpen(false);
          }, 3000);
        }}
      >
        i
        <div className={classes.passInfoBlock} style={isInfoOpen ? { display: 'block' } : {}}>
          <div className={classes.statsRow}>
            <span className={classes.statsDesc}>PTO Allowed</span>
            <span className={classes.blackColor}>{userStats?.allowed || 0}</span>
          </div>
          <div className={classes.statsRow}>
            <span className={classes.statsDesc}>PTO Designated</span>
            <span className={classes.blackColor}>{userStats?.designated || 0}</span>
          </div>
          <div className={classes.statsRow}>
            <span className={classes.statsDesc}>PTO Available</span>
            <span className={classes.primaryColor}>{userStats?.available || 0}</span>
          </div>
        </div>
      </div>
  );
};

export default withStyles(styles)(UserStatsTooltip)
